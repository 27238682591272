import React, {useEffect, useState} from "react";
import {useLockFn, useMount} from "ahooks";
import {Link} from "react-router-dom";
import general from "../../general";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import Card from "./Card";
import Vip from "./Vip";
import {CSSTransition} from "react-transition-group";
import URI from "urijs";
import {NavLink} from "../../lib/Navigation";
import MobileDetect from "mobile-detect";
import useActiveState from "../../lib/useActiveState";
import useUrlState from "@ahooksjs/use-url-state";

export default ({query}) => {
  const [type, setType] = useState(query.type || 'card');
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [data, setData] = useState({});
  const [card, setCard] = useState({});
  const [vip, setVip] = useState({});
  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [agent] = useActiveState('agent');
  const [user, setUser] = useActiveState('user');

  const load = () => {
    general.axios.post('/vip/getIndexData?noaccess=1')
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          setData(data);
          setCard(data.money_saving_card);
          setVip(data.vip);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  };

  useEffect(()=>{
    load();
  }, [user]);

  const urlLogin = () => {
    if(general.isLogin() && localStorage.getItem('username') == params.username) {
      return;
    }

    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useMount(urlLogin);

  const [pay, setPay] = useState();
  const [payUrl, setPayUrl] = useState();
  let [mb] = useActiveState('mb');
  useEffect(() => {
    if (pay) {
      if (mobileDetect.is('iPhone')) {
        let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
            .addQuery('username', localStorage.getItem('username'))
            .addQuery('token', localStorage.getItem('token'))
            .addQuery('grade_id', pay.grade_id)
            .addQuery('mb', localStorage.getItem('mb'))
            .addQuery('from', 'react');

        setPayUrl(url);
      } else {
        // setPay(null);
        // window.location.href="https://app.milu.com/?a=" + agent;
        let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
            .addQuery('username', localStorage.getItem('username'))
            .addQuery('token', localStorage.getItem('token'))
            .addQuery('grade_id', pay.grade_id)
            .addQuery('mb', localStorage.getItem('mb'))
            .addQuery('from', 'react');

        setPayUrl(url);
      }

      setTimeout(()=>{
        setPay(null)
      }, 8000)
    } else {
      setPayUrl(null);
    }
  }, [pay]);

  return <div className="save-index">
    <div className="save-head" style={{backgroundImage: 'url(/resources/save/top.png)'}}>
      <div className="icon">
        <Image src={ !general.isLogin() ? '/resources/user/avatar.png' : user.avatar_status === '-1'? '/resources/user/avatar.png' :user.icon_link } alt="个人头像" className="avatar"/>
        {/* <Image src={data.avatar}/> */}
      </div>
      <div className="info">
        <div className="name">
          {data.nicknaame}
          {general.isLogin() && type === 'vip' ? <img src={'/resources/user/' + vip.grade_id + '.png'}/> : null}
        </div>
        <div className="desc">
          {type === 'card' ? <>
            {card.is_buy ? card.end_date_time : '暂未购买'}
          </> : <>
            {vip.is_vip ? vip.end_date_time : '暂未购买'}
          </>}
        </div>
      </div>
      {type === 'card'
          ? <NavLink to="/card/record" className="operate">购买记录</NavLink>
          : 
          // <NavLink to="//sys.wakaifu.com/home/vip/summary.html" iframe={{title: '会员说明'}} className="operate">会员说明</NavLink>
          null
          }
    </div>
    <div className="save-type">
      {/* {[{text: '省钱月卡', id: 'card'}, {text: '会员中心', id: 'vip'}].map(item => <div className={'item' + (item.id === type ? ' active' : '')} onClick={() => setType(item.id)}>{item.text}</div>)} */}
    </div>
    {type === 'card' ? <Card datas={card.items || []} setPay={setPay} vip={query.vip}/> : <Vip datas={vip.items || []} setPay={setPay} vipParam={{'birthdayVoucherAmount':vip.birthdayVoucherAmount,'is_vip':vip.is_vip}} packs={vip.packs}/>}
    <CSSTransition in={!!pay} timeout={250} unmountOnExit={true}>
      <div className="save-pay">
        <div className="mask" onClick={() => setPay(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <object data={payUrl}
            width="100%"
            height={user.is_oversea == '2' ? '370px' : '200px'}
            className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }
            type="text/html">
          </object>
          {/* <iframe className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') } src={payUrl}/> */}
        </div>
      </div>
    </CSSTransition>
  </div>;
};