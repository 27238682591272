import {BellOutlined, SettingOutlined} from '@ant-design/icons';
import {NavLink} from "../../lib/Navigation";
import general, {redux} from "../../general";
import useActiveState from "../../lib/useActiveState";
import useActiveEffect from "../../lib/useActiveEffect";
import {useLockFn, useMount, useScroll} from "ahooks";
import {useEffect, useState} from "react";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {Link} from "react-router-dom";
import MobileDetect from "mobile-detect";
import URI from "urijs";
import { Badge } from 'antd-mobile';
import PubSub from 'pubsub-js';
import Manager from '../components/Manager';

const mobileDetect = new MobileDetect(navigator.userAgent);

export default props => {
  const {aplus_queue} = window;
  const [config] = useActiveState('config');
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [opacity, setOpacity] = useState();
  const [coinPopState, setCoinPopState] = useState(false);
  const [unread,setUnread] = useState(0)

  redux.subscribe(()=>{
    setUnread(redux.getState().unread > 99 ? '99+' : redux.getState().unread)
  })

  let [mb] = useActiveState('mb');
  const load = useLockFn(async () => {
    // Dialog.loading();
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          if (status.error_code == 1016) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('username');
            localStorage.removeItem('token');
            localStorage.removeItem('yx_account');
            localStorage.removeItem('yx_token');
            PubSub.publish('loginSubscribe',false)
          } else {
            Dialog.error(status.error_desc);
          }
        } else {
          localStorage.setItem('yx_account',data.member_info.yx_account)
          localStorage.setItem('yx_token',data.member_info.yx_token)
          setUser({isLogin: true, ...data.member_info});
        }
      })
  });
  useMount(() => {
    if (general.isLogin()) {
      load();
    }
  });
  useActiveEffect(() => {
    if (general.isLogin()) {
      if (!user.isLogin) {
        load();
      }
    } else {
      setUser({isLogin: false});
    }
  });

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && general.isLogin()) {
      if (scroll.top < -80) {
        load();
      }
    }
  }, [scroll, props.active]);

  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        console.log()
        // opacity = Math.abs(scroll.top) / 200;
        // if (opacity > 1) {
        //   opacity = 1;
        // }
        opacity = 1;
      } else {
        opacity = 0;
      }
    }
    setOpacity(opacity);
  }, [scroll, props.active]);

  const [messageUnreadNum, setMessageUnreadNum] = useState(0);
  const messUnreadCount = useLockFn(async (parms = {}) => {
    // Dialog.loading();
    general.axios.post('mess_unread_count')
      .finally(() => {
        // Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          return;
        }
        setMessageUnreadNum(data.message_unread_num);
      })
  });

  useEffect(()=>{
    if (props.active && general.isLogin()) {
      messUnreadCount();
    }
    
  }, [props.active])

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['BrowseMyPage', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
      });
    }
  }, [props.active]);

  return <div className="user-index">
    <div className={"user-halo width "}>
      <div className="background" />
      <div className="container">
        我的
        <div className="operate">
          <NavLink to="/message" onClick={()=>Manager.push({event_id:'A-5-2'})} className="item">
            {/* <BellOutlined className="item"/> */}
            <img src="/resources/user/bell.png"/>
            {messageUnreadNum > 0 ? <i className='tip'></i> : null }</NavLink>
          <Link to="/set" onClick={()=>Manager.push({event_id:'A-5-3'})} className="item">
            {/* <SettingOutlined className="item"/> */}
            <img src="/resources/user/set.png" />
          </Link>
        </div>
      </div>
    </div>
    <div className="user-head">
      <div className="container">
        <NavLink to="/user" onClick={()=>Manager.push({event_id:'A-5-1'})} className="user">
          <div className="icon"><Image src={user.avatar_status === '-1'? '/resources/user/avatar.png' : (user.icon_link || '/resources/user/avatar.png')} alt=""/></div>
          {/* {
            user.vip_level ?
            <div className="vipcrown"><Image src="/resources/user/vipcrown.png" /></div>
            : null
          } */}
          <div className="info">
            {user.isLogin ? <>
              <div className="name">{user.nick_name}</div>
              <div className="account">账号：{user.member_name}</div>
              <div className="phone">手机：{user.mobile || '未绑定'}</div>
            </> : <div className="empty">未登录</div>}
            {/* {config.vipsignLifeTimeDesc ? <div className='usefultime'>{config.vipsignLifeTimeDesc}</div> : null} */}
            {
              mobileDetect.os() == 'iOS' ?
                config.vipsignLifeTimeDesc ? 
                <div className='usefultime'>{config.vipsignLifeTimeDesc}</div> 
                : 
                user.vipsignLifeTimeDesc ? <div className='usefultime'>{user.vipsignLifeTimeDesc}</div> : null
                
              : null
            }
            
            {/* {user.vipsignLifeTimeDesc ? <div className='usefultime'>{user.vipsignLifeTimeDesc}</div> : null} */}
          </div>

          {/** 会员月/季/年卡 **/}
          {
            user?.vipVersion == 2 ?
            <>
            {(() => {
              if(user?.vip_level == 1) {
                return <div className='rank-level'><img src="/resources/user/monthly_card.png" /></div>
              } else if(user?.vip_level == 2) {
                return <div className='rank-level'><img src="/resources/user/season_card.png" /></div>
              } else if(user?.vip_level == 3) {
                return <div className='rank-level'><img src="/resources/user/year_card.png" /></div>
              }
            })()}
            </>
            : <>
            {user?.vipVersion == 1 && user.vip_level ?
            <div className="rank-level old-level"><Image src={"/resources/user/" + user.vip_level + ".png"} /></div>
            : null}
            </>
          }
        </NavLink>
        {/* <NavLink to="/save?type=vip" className="vip" style={{backgroundImage:'url(/resources/user/bg-block.png)'}}>
          <div className="icon"><img src="/resources/user/vip.png" alt=""/></div>
          <div className="title">超级会员</div>
          <div className="info">{config.app_my_tips}</div>
          <div className="btn" style={{backgroundImage:'url(/resources/user/block-but.png)'}}>立即开通</div>
        </NavLink> */}
      </div>
    </div>
    <div className="user-body">

      <div className="economy">

      {/* <NavLink
        to={(() => {
          let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('location_url', window.location.origin)
              .addQuery('mb', mb)
              .addQuery('agent', agent)
              .addQuery('from', 'react')
              .toString();
          return url;
        })()}
        user={true} iframe={{title:'充值'}} className="item">
        <div className="info">
          <div className="name">平台币</div>
          <div className="value"><span>{user.platform_coins ? user.platform_coins.replace('.00', '') : 0}</span>&nbsp;个</div>
          <div className="ec-link-text">去充值></div>
        </div>
      </NavLink> */}

      {/* <NavLink to="/ptb" className="item">
        <div className="info">
          <div className="name">平台币</div>
          <div className="value"><span>{user.platform_coins ? user.platform_coins.replace('.00', '') : 0}</span>&nbsp;个</div>
          <div className="ec-link-text">去充值></div>
        </div>
      </NavLink> */}
      {/* <div className="icon"><img src="/resources/user/coin.png" alt=""/></div> */}

      {/* <NavLink to="/screen-welfare" replace className="item">
        <div className="info">
          <div className="name">金币</div>
          <div className="value"><span>{user.gold_coins ? user.gold_coins.replace('.00', ' ') : 0}</span>个</div>
          <div className="ec-link-text">去兑换></div>
        </div>
      </NavLink> */}
      <NavLink onClick={()=>Manager.push({event_id:'A-5-4'})}
          to={(() => {
            let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();
            return url;
          })()}
          user={true} iframe={{title:'平台币充值', sinfo:'nisptb'}} className="item" >
          <div className='info'>
            <div className='value'><span>{user.platform_coins ? user.platform_coins.replace('.00', '') : 0}</span>个</div>
            <div className='ec-link-text'>
              <div className="text-info">平台币 去充值</div>
              <div className='img'><img src="/resources/welfare/left-back.png" /></div>
            </div>
          </div>
          <div className="line"></div>
        </NavLink>
      <div className="item" onClick={() => {setCoinPopState(true);Manager.push({event_id:'A-5-5'})}}>
        <div className="info">
          {/* <div className="name">金币</div> */}
          <div className="value"><span>{user.gold_coins ? user.gold_coins.replace('.00', '') : 0}</span></div>
          <div className="ec-link-text">
            <div className='text-info'>金币  去使用</div>
            <div className='img'><img src="/resources/welfare/left-back.png" /></div>
          </div>
        </div>
        <div className="line"></div>
      </div>
      <NavLink to="/user/voucher" className="item" onClick={()=>Manager.push({event_id:'A-5-6'})}>
        <div className="info">
          {/* <div className="name">代金券</div> */}
          <div className="value"><span>{user.available_voucher ? user.available_voucher.replace('.00', '') : 0}</span></div>
          <div className="ec-link-text">
            <div className='text-info'>代金券  享优惠</div>
            <div className='img'><img src="/resources/welfare/left-back.png" /></div>
          </div>
        </div>
      </NavLink>
      </div>

      {/** new平台币 **/}
      {/* <NavLink
          to={(() => {
            let url = URI("https://sys.wakaifu.com/home/MixPay/ptb")
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('location_url', window.location.origin)
                .addQuery('agent', agent)
                .addQuery('from', 'react')
                .toString();
            return url;
          })()}
          user={true} iframe={{title:'平台币充值', sinfo:'nisptb'}} className="dollar">
        <div className='icon'><img src="/resources/user/dollar.png" /></div>
        <div className='text'>
          <div className='name'><span>{user.platform_coins ? user.platform_coins.replace('.00', '') : 0}</span>个平台币</div>
          <div className='info'>{config?.bannerPtbTips}</div>
        </div>
        <div className='operate'>去充值</div>
      </NavLink> */}

      {/* <div className="icon"><img src="/resources/user/vip.png" alt=""/></div>
        <div className="title">超级会员</div>
        <div className="info">{config.app_my_tips}</div> */}
      {/* <NavLink to="/save?type=vip" className="vip" style={{backgroundImage:'url(/resources/user/bg-block.png)'}}>
        <div className='desc'>
          <div className='icon'>
            <img src="/resources/user/vip2.png"/>
          </div>
          <div className="tips">{config.app_my_tips}</div>
        </div>
        <div className="btn">
          <img src="/resources/user/vip-but.png" />
        </div>
      </NavLink> */}

      {/*** 省钱卡/超级会员 ***/}
      {!config.isHideVipACard ? 
      <div className='general-card'>
        <div className='items'>
          <NavLink user={false} onClick={()=>{
            Manager.push({event_id:'A-5-7'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_super_member', 'CLK', {}]
            })
          }} to="/save/newvip" className="item" style={{backgroundImage:'url(/resources/user/m.png)'}}>
            <div className='title'>
              超级会员
            </div>
            <div className='desc'>
              {config?.vip_desc}
            </div>
          </NavLink>
          <NavLink user={false} onClick={()=>{
            Manager.push({event_id:'A-5-8'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_save_money_card', 'CLK', {}]
            })
          }} to="/save/newcard" className="item" style={{backgroundImage:'url(/resources/user/c.png)'}}>
            <div className='title'>
              省钱卡
            </div>
            <div className='desc'>
              {config?.month_card_desc}
            </div>
          </NavLink>
        </div>
      </div> : null}

      <NavLink user={false} onClick={()=>{
        Manager.push({event_id:'A-5-10'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_cloud_hangers', 'CLK', {}]
            })
          }} to="/hangup" className="hangup" style={{backgroundImage:'url(/resources/user/index1/hangupbg.png)',backgroundSize:'100% 100%'}}>
        <div className="item">
          <img className="icon" src="resources/user/index1/hangup.png" alt="" />
          <div className="content">24小时在线挂机，省时又省心</div>
          <img className="more" src="resources/user/index1/more.png" alt="" />
        </div>
      </NavLink>

      <div className="general-title">
        <div className="title">玩家服务</div>
      </div>
      <div className="general-func">
        {/* <div className="title">玩家服务</div> */}
        <div className="items clearfix">
          {localStorage.getItem('yx_login') ? <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-11'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_my_group_chat', 'CLK', {}]
            })
          }} to="/clusters" className="item">
            <div className="icon"><img src="/resources/user/anomaly-im.png" alt=""/></div>
            <Badge content={unread} style={{
              '--top':'-33px','--right':'15px',display:unread === 0 ? 'none' : ''
            }}>
              <div className="text">我的群聊</div>
            </Badge>
          </NavLink> : 
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-11'})
              PubSub.publish('loginSubscribe',true)
              setTimeout(()=>{
                general.history.push('/clusters')
              },3000)
          }} className="item">
            <div className="icon"><img src="/resources/user/anomaly-im.png" alt=""/></div>
            <div className="text">我的群聊</div>
          </NavLink>}
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-14'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_my_game', 'CLK', {}]
            })
          }} to="/user/game" className="item">
            <div className="icon"><img src="/resources/user/game.png" alt=""/></div>
            <div className="text">我的游戏</div>
          </NavLink>
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-12'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_my_gift_bag', 'CLK', {}]
            })
          }} to="/user/gift" className="item">
            <div className="icon"><img src="/resources/user/gift.png" alt=""/></div>
            <div className="text">我的礼包</div>
          </NavLink>
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-13'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_my_bill', 'CLK', {}]
            })
          }} to="/user/bill" className="item">
            <div className="icon"><img src="/resources/user/new-bill.png" alt=""/></div>
            <div className="text">我的账单</div>
            {/* {user.available_voucher > 0 ? <span className="angle">{user.available_voucher}</span> : null} */}
          </NavLink>
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-15'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_my_footprint', 'CLK', {}]
            })
          }} to="/user/record" className="item">
            <div className="icon"><img src="/resources/user/record.png" alt=""/></div>
            <div className="text">我的足迹</div>
          </NavLink>
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-16'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_rebate_center', 'CLK', {}]
            })
          }} to="/rebate" className="item">
            <div className="icon"><img src="/resources/user/rebate.png" alt=""/></div>
            <div className="text">我的返利</div>
          </NavLink>
          <NavLink onClick={()=>{
            Manager.push({event_id:'A-5-17'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_recharge_transfer', 'CLK', {}]
            })
          }} to="/around" className="item">
            <div className="icon"><img src="/resources/user/move.png" alt=""/></div>
            <div className="text">充值转游</div>
          </NavLink>
          {/* <NavLink to="/recovery" className="item">
            <div className="icon"><img src="/resources/user/recovery.png" alt=""/></div>
            <div className="text">小号回收</div>
          </NavLink> */}
          {/* {agent == 'y537' ? '' :
          <NavLink to="/recovery" className="item">
            <div className="icon"><img src="/resources/user/recovery.png" alt=""/></div>
            <div className="text">小号回收</div>
          </NavLink>
          } */}
          <NavLink user={false} onClick={()=>{
            Manager.push({event_id:'A-5-18'})
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['mine_Invite_friends', 'CLK', {}]
            })
          }} to={config?.inviteFriendsType == 2 ? "/newinvite" : "invite"} className="item">
            <div className="icon"><img src="/resources/user/invite-new.png" alt=""/></div>
            <div className="text">邀请好友</div>
          </NavLink>
        </div>
      </div>
      {/* <NavLink to="/save?type=card" className="card">
        <img src="/resources/user/card2.png?version=20210817" alt=""/>
      </NavLink> */}

      <div className="more-title">
        <div className="title">更多服务</div>
      </div>
      <div className="more-func">
        <div className="items clearfix">
          <Link to={'/service'} onClick={()=>Manager.push({event_id:'A-5-19'})} className="item">
            <div className="icon"><img src="/resources/user/service-dark-new.png" alt=""/></div>
            <div className="text">在线客服</div>
            <div className='lt'><img src="/resources/user/lt.png" /></div>
          </Link>
          <NavLink to="/feedback" onClick={()=>Manager.push({event_id:'A-5-20'})} className="item">
            <div className="icon"><img src="/resources/user/feedback.png" alt=""/></div>
            <div className="text">投诉反馈</div>
            <div className='lt'><img src="/resources/user/lt.png" /></div>
          </NavLink>
          {/* <Link to={'/video?url=' + config.intro_video_url} className="item">
            <div className="icon"><img src="/resources/user/intro.png" alt=""/></div>
            <div className="text">平台介绍</div>
          </Link>
          <div className="item" onClick={() => Dialog.info('暂无QQ群')}>
            <div className="icon"><img src="/resources/user/group.png" alt=""/></div>
            <div className="text">加入QQ群</div>
          </div> */}
          <NavLink to="/user" onClick={()=>Manager.push({event_id:'A-5-22'})} className="item">
            <div className="icon"><img src="/resources/user/save.png" alt=""/></div>
            <div className="text">账号安全</div>
            <div className='lt'><img src="/resources/user/lt.png" /></div>
          </NavLink>
        </div>
      </div>
    </div>

    {/** 金币弹框 **/}
    {coinPopState ?
    <div className='user-coin-pop-index'>
      <div className='coin-pop-site'>
        <div className='content'>
          <div className='head'>
            <NavLink to="/user/bill?type=2" onClick={() => setCoinPopState(false)}>金币明细{'>'}</NavLink>
          </div>
          <div className='title'>
            <img src="/resources/user/coin-pop-img.png"/>
            <p>
              金币的使用和获取
            </p>
          </div>
          <div className='text'>
            <div className='desc'>
              <div className='name'>
                金币使用说明
              </div>
              <div className='info'>
                {/* 金币等同于平台币，可用于游戏内支付时抵扣，10金币等于1人民币，部分特殊BT游戏不支持金币支付。 */}
                金币作用等同于平台币，可用于游戏内支付抵扣，10金币相当于1元。折扣/H5和部分BT游戏不支持金币支付，具体以游戏支付页面显示为准
              </div>
            </div>
            <div className='desc'>
              <div className='name'>
                金币获取方式
              </div>
              <div className='info'>
                每日签到/福利中心任务/邀请好友/平台活动等...
              </div>
            </div>
          </div>
          <div className='operate' onClick={()=>setCoinPopState(false)}>
            我知道了
          </div>
        </div>

        <div className='close' style={{backgroundImage:'url(/resources/party/close.png)'}} onClick={()=>setCoinPopState(false)}>
        </div>
      </div>
    </div>
    : null}

  </div>;
};