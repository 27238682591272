import {useEffect, useState} from "react";
import general, {redux} from "../../general";
import {ArrowUpOutlined, FireOutlined,} from '@ant-design/icons';
import {Link, useHistory, useLocation} from "react-router-dom";
import {useLocalStorageState} from "ahooks";
import Image from "../../lib/Image";
import Dialog from "../../lib/Dialog";
import useActiveState from '../../lib/useActiveState';

export default props => {
  const {aplus_queue} = window;
  let history = useHistory();
  let location = useLocation();
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [searchHistory, setSearchHistory] = useLocalStorageState('search-history', []);
  const [kws, setKws] = useLocalStorageState('search-kws', []);
  const [gameGroups, setGameGroups] = useLocalStorageState('search-gamegroups', [
    {text: '猜你想搜', games: []},
    {text: '热搜游戏', games: []}
  ]);

  const [init, setInit] = useState(false);

  const load = () => {
    // Dialog.loading();
    general.axios.get('/base/search/like', {
      headers: {username: ''}
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            return;
          }
          setInit(true);
          setKws(data.search_keywords);
          gameGroups[1].text = data.title1;
          gameGroups[0].text = data.title2;
          gameGroups[1].games = data.recommend_game_list.length > 10 ? data.recommend_game_list.splice(0,10) : data.recommend_game_list;
          gameGroups[0].games = data.guess_youlike.length > 10 ? data.guess_youlike.splice(0,10) : data.guess_youlike;
          // console.log(data);
          setGameGroups([...gameGroups]);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active && !init && history.action == 'PUSH') {
      load();
    }
  }, [props.active]);

  /*
  useEffect(() => {
    // Dialog.loading();
    general.axios.get('/base/search/index', {
      headers: {username: ''}
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            return;
          }
          setKws(data.search_keywords);
          gameGroups[1].text = data.title1;
          gameGroups[0].text = data.title2;
          gameGroups[1].games = data.recommend_game_list;
          gameGroups[0].games = data.guess_youlike;
          // console.log(data);
          setGameGroups([...gameGroups]);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }, []);*/

  //搜索热词点击 埋点
  const clickHotSearchWords = (kw) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickHotSearchWords', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), info: kw, agent: agent}]
    });
  }

  //搜索推荐点击 埋点
  const clickSearchRecommend = (game) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickSearchRecommend', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game.game_name, agent: agent}]
    });
  }

  return <div className={'search-cover ' + (props.kw ? '' : 'active')}>

    {searchHistory && searchHistory.length > 0 ? 
      <>
      <div className="title history-title">
        <span>搜索历史</span>
        <img src="/resources/search/history_unset.png" onClick={()=>setSearchHistory([])}/>
      </div>
      <div className="kws clearfix history-kws">
        {
          searchHistory.map((kw, index) => <div className="kw" onClick={()=>{
            props.setKw(kw);props.setInputText(kw);clickHotSearchWords(kw)
          }}>
            {kw}
          </div>)
        }
      </div>
      </>
    : null}

    <div className="title">搜索热词</div>
    <div className="kws clearfix">
      {kws.map((kw, index) => <div className="kw" onClick={() => {
          if(kw.type == 'tag') {
            redux.dispatch({
              type: 'type',
              data: {
                screenRouter: {
                  screen: '/game',
                  type: 0,
                  tag: kw.tid,
                },
              }
            });
          } else if(kw.type == 'game') {
            props.setKw(kw.keywords);props.setInputText(kw.keywords);clickHotSearchWords(kw.keywords)
          }
        }}>
        {index == 0 ? <img src="/resources/search/n-hot.png" className="n-hot" /> : null}
        {index == 1 ? <img src="/resources/search/n-top.png" className="n-top" /> : null}
        {/* {index == 0 ? <FireOutlined className="icon"/> : null}
        {index == 1 ? <ArrowUpOutlined className="icon"/> : null} */}
        {kw.keywords}
      </div>)}
    </div>
    <div className="groups clearfix">
      <div className="container">
      {gameGroups.map(gameGroup => <div className="group">
        <div className="title">{gameGroup.text}</div>
        <div className="games">
          {gameGroup.games.map((game, index) => <Link to={'/game?id=' + game.game_id} className="game" onClick={()=>clickSearchRecommend(game)}>
            <div className="index">{index + 1}</div>
            <div className="icon"><Image src={game.game_icon}/></div>
            <div className="info">
              <div className="name">
                <span>{game.game_name}</span>
                {/* <div className={" " + (game.nameRemark ? " name-new" : "")}>
                  {game.game_name}
                </div>
                {game.nameRemark != '' ? <div className="nameRemark">{game.nameRemark}</div> : null} */}
                {index == 0 ? <img src="/resources/search/straw.png" /> : null}
                {index == 1 ? <img src="/resources/search/new.png" /> : null}
                {index == 2 ? <img src="/resources/search/fine.png" /> : null}
              </div>
              <div className="desc">
                {/* {
                  game.nameRemark ?
                  <span className="nameRemark">
                    {game?.nameRemark}&nbsp;
                  </span>
                  : null
                } */}
                {game.tagsName}
                {/* {game.tagsName.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")} */}
              </div>
            </div>
          </Link>)}
        </div>
      </div>)}
      </div>
    </div>
  </div>;
};