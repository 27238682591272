import React, {useEffect, useState} from "react";
import Dialog from "../../../lib/Dialog";
import {NavLink} from "../../../lib/Navigation";
import {useLockFn} from "ahooks";
import useActiveState from "../../../lib/useActiveState";
import general from "../../../general";
import {useHistory, useLocation} from "react-router-dom";
import URI from "urijs";

const tasks = [
    {
      name: '设置头像',
      reward: '5金币',
      id: 'avatar',
      info: '第一眼的感觉很重要',
      class: 'avatar',
      operate: '去设置',
      taskname: 'avatar',
      display: true,
      nologin_display: true,
    },
    {
      name: '设置昵称',
      reward: '5金币',
      id: 'nickname',
      info: '给自己取个响亮的名字吧',
      class: 'nickname',
      operate: '去设置',
      taskname: 'nickname',
      display: true,
      nologin_display: true,
    },
    {
      name: '绑定手机',
      reward: '5金币',
      id: 'mobile',
      info: '绑定手机号 特权有保障',
      operate: '去绑定',
      class: 'phone',
      taskname: 'mobile',
      display: true,
      nologin_display: true,
    },
    {
      name: '实名认证',
      reward: '25金币',
      id: 'authentication',
      info: '全力以赴为您的安全保驾护航',
      operate: '去认证',
      class: 'authent',
      taskname: 'authentication',
      display: true,
      nologin_display: true,
    },
    {
      name: '畅玩任一游戏',
      reward: '10金币',
      id: 'playGame',
      info: '畅玩任何一款游戏均可领金币哦',
      operate: '去完成',
      class: 'playgame',
      taskname: 'login_game',
      display: true,
      nologin_display: false,
    },
    {
      name: '关注公众号',
      reward: '10金币',
      id: 'weixin',
      info: '掌握一手游戏资讯，不错过任何福利',
      operate: '去关注',
      class: 'wechat',
      taskname: 'weixin',
      display: true,
      nologin_display: true,
    },
    {
        name: '关注抖音账号',
        reward: '10金币',
        id: 'douyin',
        info: '掌握一手游戏资讯，不错过任何福利',
        operate: '去完成',
        class: 'playgame',
        taskname: 'douyin',
        display: false,
        nologin_display: true,
    },
];


export default props => {
    const location = useLocation();
    const history = useHistory();
    const [data, setData] = useState();
    // useEffect(() => {
    //     !data && setData(datas.day1_info);
    // }, [datas]);

    const [giftList, setGiftList] = useState([]);
    // useEffect(() => {
    //     !giftList && setGiftList(datas.gift_list);
    // }, [datas.gift_list]);
    const [douyinText, setDouyinText] = useState([]);
    const [weixinText, setWeixinText] = useState([]);
    let [agent, setAgent] = useActiveState('agent');

    const load = () => {
        if(!general.isLogin()) {
            return false;
        }
        Dialog.loading();
        general.axios.post('/user/active2203/getIndexData')
          .finally(() => {
            Dialog.close();
          })
          .then(response => {
            let {status, data} = response.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              return;
            }
            setData(data.day1_info);
            setGiftList(data.gift_list);
            if(data.day1_info.tips) {
                let tip = data.day1_info.tips.split('\n');
                setDouyinText(tip);
            }

            if(data.day1_info.tipswx) {
                let tipwx = data.day1_info.tipswx.split('\n');
                setWeixinText(tipwx);
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
    }

    useEffect(() => {
        load();
    }, [props.active]);

    // console.log(data);

    const [bouchStatus, setBouchStatus] = useState(false); //选游戏状态
    const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
    const [authData, setAuthData] = useState({}); //实名认证参数值
    const [packids, setPackids] = useState([]);
    const [user, setUser] = useActiveState('user');
    const [config] = useActiveState('config');

    // console.log(datas);
    //实名认证
    const certifiy = useLockFn(()=>{
        if(!authData.real_name) {
            Dialog.info('请输入姓名');
            return;
        }
        if(!authData.identity_card){
            Dialog.info('请输入身份证号');
            return;
        }
        Dialog.loading();
        general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
        .finally(() => {
            Dialog.close();
        })
        .then(res => {
            let {status, data} = res.data;
            
            if (!status.succeed) {
                Dialog.info(status.error_desc);
            } else {
                Dialog.info("认证成功");
                setIsCheckAuth(false);
                setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
                load();
            }
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    })

    const addPack = (item) => {
        if(item.is_received) {
            // Dialog.info("已经领取过了");
            return false;
        }
        let packid = item.packid;
        let pack = packids.indexOf(packid);

        if(pack != -1) {
            // let arr = packids;
            // for(let i = 0; i < arr.length; i++) {
            //     if(arr[i] == item) {
            //         arr.splice(i, 1);
            //     }
            // }
            // setPackids(arr);
            
        } else {
            // Dialog.info("bbbbb");
            setPackids([...packids, packid]);
        }
    }

    const giftRecelve = () => {
        if(!general.isLogin()) {
            PubSub.publish('alert-login',true)
            return false;
        }
        Dialog.loading();
        
        general.axios.post('/user/active2203/receive', {type:1, pack_ids:packids.toString()})
        .finally(() => {
            Dialog.close();
        })
        .then(res => {
            let {status, data} = res.data;
            
            if (!status.succeed) {
                Dialog.info(status.error_desc);
            } else {
                Dialog.info("领取成功");
                setBouchStatus(false);
                load();
            }
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    }

    //领取
    const handle = task => {
        if(!general.isLogin()) {
            PubSub.publish('alert-login',true)
        } else {
            let name = task.taskname;
            // console.log(data[name]);
            //1：领取 2：已领取 0：未完成
            if(data[name] == 1) {
                Dialog.loading();
                general.axios.post('/user/task/takeBalance', {name: task.id, novice: '1'})
                .finally(() => {
                    Dialog.close();
                })
                .then(res => {
                    let result = res.data;
                    if (!result.status.succeed) {
                        Dialog.error(result.status.error_desc);
                    } else {
                        Dialog.success('领取成功');
                        load();
                    }
                })
                .catch(err => {
                    Dialog.error(err.message);
                });
            } else if(data[name] == 0) {
                if (task.id === 'avatar') {
                    general.history.push('/user');
                } else if (task.id === 'nickname') {
                    general.history.push('/user/nickname');
                } else if (task.id === 'mobile') {
                    general.history.push('/user');
                } else if (task.id === 'authentication') {
                    setIsCheckAuth(true);
                } else if (task.id === 'playGame') {
                    Dialog.alert('温馨提示', '畅玩任何一款游戏均可领取金币哦');
                } else if (task.id === 'weixin' || task.id === 'douyin') {
                    setCdk(task.id);
                }
            }
        }
    }

    const [cdk, setCdk] = useState(false);
    const [cdkIng, setCdkIng] = useState(false);
    const [cdkCode, setCdkCode] = useState('');
    const cdkHandle = () => {
        if (!cdkCode) {
            Dialog.error('请输入兑换码');
            return;
        }
        if (cdkIng) {
            return;
        }
        setCdkIng(true);
        Dialog.loading();
        general.axios.post('/user/task/exchange', {type: cdk, code: cdkCode})
        .finally(() => {
            setCdkIng(false);
            Dialog.close();
        })
        .then(response => {
            let result = response.data;
            if (!result.status.succeed) {
                Dialog.error(result.status.error_desc);
            } else {
                setCdk(false);
                load();
            }
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    }

    const bouch = () => {
        if(!general.isLogin()) {
            PubSub.publish('alert-login',true)
        } else {
            setBouchStatus(true)
        }
    }

    return <div className="one-day-index">
        <div className="width">
            <div className="first-card">
                <h1>
                    新人入门充值卡
                </h1>
                {data ?
                <>
                {giftList.length > 0 ? 
                <img src="/resources/party/first-card.png" onClick={()=>bouch()}/>
                : <img src="/resources/party/first-card-alreadly.png" />}</>
                : <img src="/resources/party/first-card.png" onClick={()=>bouch()}/>
                }
            </div>

            <div className="first-task">
                <h1>
                    免费领充值
                </h1>
                <div className="tasks">
                    {tasks.map(task => general.isLogin() ? data ? data[task.taskname] != undefined ? <div className={"task " + task.class } onClick={() => handle(task)}>
                        <div className="icon"><img src={'/resources/party/' + (task.class) + '.png'} alt=""/></div>
                        <div className="info">
                            <div className="name">
                            {task.name}
                            </div>
                            <div className="desc">{task.reward}</div>
                        </div>
                        <div className={'operate ' + ( data ? data[task.taskname] == 2 ? 'alreadly-lq' : '':'')}>
                            {data ? <>
                            {(()=>{
                                let name = task.taskname;

                                if(data[name] == 0) {
                                    return '去完成';
                                } else if(data[name] == 1) {
                                    return '领取';
                                } else if(data[name] == 2) {
                                    return  '已领取';
                                } else {
                                    return '去完成';
                                }
                            })()}</>
                            : '去完成'}
                        </div>
                    </div> : null : null : (task.nologin_display ? <div className={"task " + task.class } onClick={() => handle(task)}>
                        <div className="icon"><img src={'/resources/party/' + (task.class) + '.png'} alt=""/></div>
                        <div className="info">
                            <div className="name">
                            {task.name}
                            </div>
                            <div className="desc">{task.reward}</div>
                        </div>
                        <div className={'operate ' + ( data ? data[task.taskname] == 2 ? 'alreadly-lq' : '':'')}>
                            {data ? <>
                            {(()=>{
                                let name = task.taskname;

                                if(data[name] == 0) {
                                    return '去完成';
                                } else if(data[name] == 1) {
                                    return '领取';
                                } else if(data[name] == 2) {
                                    return  '已领取';
                                } else {
                                    return '去完成';
                                }
                            })()}</>
                            : '去完成'}
                        </div>
                    </div>:null)


                    )}
                </div>
            </div>
        </div>

        {/***选游戏弹框 */}
        {bouchStatus ? 
        <div className="bouch-mask">
            <div className="mask-site">
                <div className="mask-content">
                    <div className="head">
                        <div className="title">648充值</div>
                        <div className="close" onClick={()=>setBouchStatus(false)}><img src="/resources/party/couple-close.png" /></div>
                    </div>
                    <div className="wp">
                        <div className="desc">
                            <div>请选择感兴趣的游戏（可多选），再领取充值卡礼包，充值卡的具体价值以礼包详情为准。</div>
                            <div className="tips"> <span>*</span>领取后可前往我的页面-玩家服务-我的礼包中查看兑换码。</div>
                        </div>
                        <div className="sel-list">
                            {
                                giftList ?
                                <>
                                {giftList.map((item, index) => <div className={"new-week-item " + (item.is_received ? '' : packids.indexOf(item.packid) > -1 ? " checked" : "" ) } onClick={() => addPack(item)} >
                                    <div className="icon">
                                        <img src={item.logo} />
                                    </div>
                                    <div className="text">
                                        <div className="name">{item.gamename}</div>
                                        <div className="info">{item.versioncode}</div>
                                        <div className="info2">
                                            {
                                                item.version ? 
                                                <>
                                                {item.version.map((v, k) => <div className="tag">{v}</div>)}
                                                </>
                                                :null
                                            }
                                        </div>
                                    </div>
                                    {item.is_received ? null :
                                        <div className="status"></div>
                                    }
                                </div>)}
                                </>
                            : null
                            }
                        </div>
                    </div>
                    <div className="accept" onClick={giftRecelve}>
                        收下
                    </div>
                </div>
            </div>
        </div>
        :null}

        {/** 实名认证 **/}
        {isCheckAuth ?
        <div className="pay-check-auth">
            <div className="auth-index">
                <div className="close" onClick={() => setIsCheckAuth(false)}>
                    <img src="/resources/user/close.png" />
                </div>
                <div className="title">
                    <div className="title-img">
                        <img src="/resources/user/auth-title.png" />
                    </div>
                    <div>
                        <h3>实名认证</h3>
                        <p>
                        {config.authTopTips}
                        {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
                        </p>
                    </div>
                </div>

                <div className="auth-user-realname">
                    <div className="auth-user-input">
                        <div className="name">真实姓名</div>
                        <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
                    </div>
                    <div className="auth-user-input">
                        <div className="name">身份证号</div>
                        <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
                    </div>
                    <span>
                    {config.authBottomTips}
                    {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
                    </span>
                    <div className="auth-user-btn" onClick={certifiy} >立即认证</div>
                </div>
            </div>
        </div>
        :null}

        {/** 微信公众号 **/}
        {cdk ? <div className={'cdk ' + 'weixin'}>
            <div className="mask" />
            <div className="entity" style={{backgroundImage: 'url(/resources/task/novice-cdk.png)'}}>
                {/* <div className="head"><img src={'/resources/task/weixin-title.png'} alt=""/></div> */}
                <div className="head"><img src={'/resources/task/' + cdk + '-title.png'} alt=""/></div>
                {/* {cdk == 'weixin' ? 
                <div className="info">
                    任务流程<br/>
                    <p>1.打开微信点击右上角＋，选择添加好友</p>
                    <p>2.选择公众号搜索“{agent == 'ua8x' ? '奇葩游平台' : '咪噜游戏官方平台'}”</p>
                    <p> 3.关注公众号，点击“游戏福利”获取并复制兑换码</p>
                    <p>4.返回盒子输入兑换码，完成任务，代金券到账</p>
                </div>
                : null} */}

                {cdk == 'weixin' ? 
                <div className="info">
                    任务流程<br/>
                    <p>{weixinText[0]}</p>
                    <p>{weixinText[1]}</p>
                    <p>{weixinText[2]}</p>
                    <p>{weixinText[3]}</p>
                </div>
                : null}
                {cdk == 'douyin' ? 
                <div className="info">
                    任务流程<br/>
                    <p>{douyinText[0]}</p>
                    <p>{douyinText[1]}</p>
                    <p>{douyinText[2]}</p>
                    <p>{douyinText[3]}</p>
                </div>
                : null}
                <div className="input">
                    <input type="text" placeholder="请输入兑换码"  onChange={e => setCdkCode(e.target.value)}/>
                    <div className="btn" onClick={() => cdkHandle()}>确认</div>
                </div>
                {cdk == 'weixin' ? <a href="weixin://" className="operate">前往微信</a> : null }
            </div>
            <div className="close" onClick={() => setCdk(false)}>X</div>
        </div>
        :null}

    </div>
};