import {Link, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import Dialog from "../../lib/Dialog";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import useActiveState from "../../lib/useActiveState";

export default props => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [codes, setCodes] = useState([]);
  const [rebateContent, setRebateContent] = useActiveState('rebateContent');
  const [contentText, setContentText] = useState([]);

  const load = (parms = {}) => {
    Dialog.loading();
    general.axios.post('/user/rebate/getRebateDetail', {rebate_id: props.query.id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          if (data) {
            setData(data);
            setCodes(data.codes);

            let tipwx = data?.reminder?.split('\r\n');
            setContentText(tipwx);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    load();
  }, []);

  // useEffect(() => {
  //   if(rebateContent) {
  //     let tipwx = rebateContent?.split('\r\n');
  //     setContentText(tipwx);
  //   }
  // },[rebateContent])

  return <div className="rebate-detail" >
    {/* <div className="rebate-tit-bg" style={{backgroundImage: 'url(/resources/rebate/bg01.png)'}}></div> */}
    <div className="rebate-desc-desc">
      <div className="wp">
          <div className="desc-box">
              {/* <img src="/resources/rebate/bg02.png" /> */}
              <div className="account-title">
                账号详情
              </div>
              <div className="desc-info">
              <div className="info">
                <dl>
                  <dt>游戏名称</dt>
                  <dd>{data.game_name}</dd>
                </dl>
                <dl>
                  <dt>申请账号</dt>
                  <dd>{data.game_account}</dd>
                </dl>
                <dl>
                  <dt>充值时间</dt>
                  <dd>{moment(data.recharge_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</dd>
                </dl>
                <dl>
                  <dt>游戏区服</dt>
                  <dd>{data.server_name}</dd>
                </dl>
                <dl>
                  <dt>角色名</dt>
                  <dd>{data.role_name}</dd>
                </dl>
                <dl>
                  <dt>角色ID</dt>
                  <dd>{data.role_id}</dd>
                </dl>
                <dl>
                  <dt>备注</dt>
                  <dd>{data.handle_remark}</dd>
                </dl>
                {codes.length > 0 ?
                  <dl>
                    <dt>礼包码</dt>
                    <dd>
                    {[codes[0], codes[1], codes[2]].map((item) => 
                      <>
                      {item ?
                      <div className="id-codes"><div className="codes">{item}</div><CopyToClipboard text={item} onCopy={() => Dialog.info('已复制')}><div className="btn">复制</div></CopyToClipboard></div> 
                      : null}
                      </>
                    )}
                    </dd>
                  </dl>
                : null}
                {codes.length > 3 ?
                    <div className="mores"><Link to={'/rebate/codes?id=' + data.rebate_id}>查看更多</Link></div>
                    : null}
                </div>

                <div className="data">
                  <div className="time">{moment(data.handle_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
                  <div className="amount">充值金额：<span>{data.recharge_amount}元</span></div>
                </div>

              </div>
          </div>

          {
            data?.status == '-1' && data?.remark ?
            <div className="reject">
              <div className="reject-title">驳回原因</div>
              <div className="reject-content">{data?.remark}</div>
            </div>
          : null}

          <div className="reminder">
            <div className="reminder-title">温馨提示</div>
            <div className="reminder-content">
              {
                contentText?.map(item => <p>
                  {item}
                </p>)
              }
              {/* <p>1、返利发放：晚九点前提交的返利均在一个工作日发放</p>
              <p>2、返利形式：游戏内发放（邮件领取／直接到角色上）激活 码形式发放（注意查看App信息）</p>
              <p>3、若申请后超过2个工作日未收到，可点击详情内申请核实</p> */}
            </div>
          </div>

          {/* <div className="title">
            <img src="/resources/rebate/line-left.png" alt=""/>
            温馨提示
            <img src="/resources/rebate/line-right.png" alt=""/>
          </div>
          <div className="remind">
            <p>返利发放：晚九点前提交的返利均在一个工作日发放</p>
            <p>返利形式：游戏内发放（邮件领取／直接到角色上）激活 码形式发放（注意查看App信息）</p>
            <p>若申请后超过2个工作日未收到，可点击详情内申请核实</p>
          </div> */}

          <div className="operate">
            {/* <Link to={'/rebate/submit?id=' + data.rebate_id} onClick={() => {
              general.rebate = {
                ...data,
                last_recharge_time: data.recharge_time,
                can_rebate_amount: data.recharge_amount,
              }
            }} className="btn">重新提交</Link> */}

            {data.status === '-1' ?
            <Link to={'/rebate/submit?id=' + data.rebate_id} onClick={() => {
              general.rebate = {
                ...data,
                last_recharge_time: data.recharge_time,
                can_rebate_amount: data.recharge_amount,
              }
            }} className="btn">重新提交</Link>
            : null}
            {
              data?.wechatWorkUrl ? 
              <div className="btn"  onClick={()=>window.location.href = data.wechatWorkUrl}>联系客服</div>
              :
              <Link to={"/service"} className="btn">
                联系客服
              </Link>
            }
            {/* <Link to={data?.wechatWorkUrl ? data.wechatWorkUrl : '/service'} className="btn">联系客服</Link> */}
            {/* <Link to={'/service'} className="btn">联系客服</Link> */}
          </div>
          <div className="tips">此笔返利没有收到或有任何疑问？（请在15天内提交核实申请）</div>
      </div>
    </div>
  </div>;
};