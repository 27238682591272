import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {useUpdateEffect, useScroll} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import Subtitle from "../../components/Subtitle";

export default props => {
  const [agent] = useActiveState('agent');
  const [init, setInit] = useState();
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [randomColorInfo] = useActiveState('randomColorInfo');//随机颜色
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      type: 2,
      game_species_type: props.type,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination = {page: parms.page};
    }
    general.axios.post('/recentlyPlayGame', tmp)
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setInit(true);
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          result.game_list.forEach(d => {
            d.infoColor = randomColorInfo[parseInt(Math.random() * 4)]
          })
          setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active]);
  // useUpdateEffect(() => {
  //   if (props.active && (!init || props.action === 'POP')) {
  //     load();
  //   }
  // }, [props]);

  const scroll = useScroll();
  useEffect(() => {
    if (props.active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20  > $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  return <div className="play-game">
    {games.map(game => <Link to={'/game?id=' + game.game_id + '&tt=1&agent='+agent} className="item">
      <div className="icon">
        <Image src={game.game_image.thumb}/>
      </div>
      <div className="subject">
        <div className="text">
          <div className="name">
            {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? " name-new" : "")}>
              {game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null }
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {/* {
              game.nameRemark ?
              <span className="nameRemark">
                {game?.nameRemark}&nbsp;
              </span>
              : null
            } */}
            <div className="scrole-stat">
              <img src="/resources/game/score.png" />
              <span>{game.score}</span>
            </div>
            {game.game_classify_type.trim()}
            <Subtitle>{game.nameRemark}</Subtitle>
          </div>
          {/* <div className="info2"> */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : game.introduction} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <span className="tag"> <span className="tagtips"><div className="first-test">{item.slice(0,1)}</div><span className="other">{item.slice(1)}</span></span> </span>) : <span style={{color: game.infoColor}}>{game.introduction}</span>} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <span className="tag">{item}</span>) : <span style={{color: game.infoColor}}>{game.introduction}</span>} */}
          {/* </div> */}
          <div className="info3">
            {
              game.bottom_lable ? 
              <>
              {Object.keys(game.bottom_lable).length > 0 ? 
              <div style={{display:'flex',overflow:'hidden'}}>
                <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
              </div> : null }
              </>
              : null
            }
            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
            {game.is_support_accele ? <div className="speed">可加速</div> : null}
            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
            {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}
          </div>
          {game.discount < 1 ? <div className="discount"><span>{game.discount * 10}</span>折</div> : null}
        </div>
        {/* <div className="btn">{game.game_species_type != 3 ? '下载' : '开始'}</div> */}
      </div>
    </Link>)}
    {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {games.length === 0 ? <div className="recovery-empty"><img src="/resources/user/game_empty.png" alt=""/>暂未玩过任何游戏</div> : null}
  </div>;
};