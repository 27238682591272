import general from "../../general";
import {useEffect, useState} from "react";
import {prod} from "../../env";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";
import {useLocalStorageState, useLockFn} from "ahooks";

const tasks = [
  {
    name: '设置头像',
    reward: '＋5金币',
    id: 'avatar',
    info: '第一眼的感觉很重要',
    operate: '去设置',
    invasion:true,
    operalias: 'go-set'
  },
  {
    name: '设置昵称',
    reward: '＋5金币',
    id: 'nickname',
    info: '给自己取个响亮的名字吧',
    operate: '去设置',
    invasion:true,
    operalias: 'go-set'
  },
  {
    name: '绑定手机',
    reward: '＋10金币',
    id: 'mobile',
    info: '绑定手机号 特权有保障',
    operate: '去绑定',
    invasion:true,
    operalias: 'go-bind'
  },
  {
    name: '实名认证',
    reward: '＋30金币',
    id: 'authentication',
    info: '全力以赴为您的安全保驾护航',
    operate: '去认证',
    invasion:true,
    operalias: 'go-certification'
  },
  {
    name: '畅玩任一游戏',
    reward: '＋10金币',
    id: 'playGame',
    info: '畅玩任何一款游戏均可领金币哦',
    operate: '去完成',
    invasion:true,
    operalias: 'go-bind'
  },
  {
    name: '关注微信公众号',
    reward: '＋10金币',
    id: 'weixin',
    info: '掌握一手游戏资讯，不错过任何福利',
    operate: '去关注',
    invasion:true,
    operalias: 'go-focus'
  },
  {
    name: '关注抖音账号',
    reward: '＋10金币',
    id: 'douyin',
    info: '关注官方抖音，了解最新游戏动态',
    operate: '去关注',
    invasion:false,
    operalias: 'go-focus'
  },
];

// const router = [];

export default props => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [config] = useActiveState('config');
  const [authData, setAuthData] = useState({}); //实名认证参数值
  // const [isDY, setIsDy] = useState(false);
  // const [newTaskList, setNewTaskList] = useLocalStorageState('task-navlists', tasks);
  // const [TaskList, setTaskList] = useLocalStorageState('task-lists', []);
  const [douyinText, setDouyinText] = useState([]);
  const [weixinText, setWeixinText] = useState([]);
  const [user, setUser] = useActiveState('user');

  let [agent] = useActiveState('agent');
  let [app] = useActiveState('app');

  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    general.axios.post('/user/Task2207/getNoviceData')
        .finally(() => {
          setLoading(false);
          Dialog.close();
        })
        .then(response => {
          setLoaded(true);
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            // console.log(result.data.task_info);
            setData(result.data);
            
            // if(result.data.task_info.douyin) {
            //   // setIsDy(true);
            //   let tip = result.data.task_info.douyin.tips.split('\n');
            //   setDouyinText(tip);
            // }

            // if(result.data.task_info.weixin) {
            //   let tipwx = result.data.task_info.weixin.tips.split('\n');
            //   setWeixinText(tipwx);
            // }
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(() => {
    if (props.active && (!loaded || props.action === 'POP')) {
      load();
    }
  }, [props.active]);

  // console.log(data);
  // useEffect(() => {
  //   if(newTaskList.length > 0) {
  //     let menus = [...router];
  //     newTaskList.forEach((item, index) => {
  //       if(item.id == 'douyin') {
  //         item.invasion = false;
  //         if(isDY) {
  //           item.invasion = true;
  //         }
  //       }
  //       if(item.id == 'playGame') {
  //         item.invasion = true;
  //         if(isDY) {
  //           item.invasion = false;
  //         }
  //       }

  //       menus.splice(6,0, {
  //         name: item.name,
  //         reward: item.reward,
  //         id: item.id,
  //         info: item.info,
  //         operate:item.operate,
  //         invasion:item.invasion,
  //       })
  //     });

  //     setTaskList(menus);
  //   }
  // }, [newTaskList, data, isDY]);

  const [receiving, setReceiving] = useState(false);
  const handle = task => {
    // let state = data[task.id];
    if (!task.isReceivedReward) {
      if (task.isCompleted) {
        if (receiving) {
          return;
        }
        setReceiving(true);
        Dialog.loading();
        general.axios.post('/user/task/takeBalance', {name: task.name})
          .finally(() => {
            setReceiving(false);
            Dialog.close();
          })
          .then(res => {
            let result = res.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              Dialog.success('领取成功');
              // data[task.name].isCompleted = true;
              // data[task.name].isReceivedReward = true;
              // setData({...data});
              load();
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
      } else {
        if (task.name === 'avatar') {
          general.history.push('/user');
        } else if (task.name === 'nickname') {
          general.history.push('/user/nickname');
        } else if (task.name === 'mobile') {
          general.history.push('/user/phone');
        } else if (task.name === 'authentication') {
          setIsCheckAuth(true);
          // general.history.push('/user/realname');
        } else if (task.name === 'playGame') {
          Dialog.alert('温馨提示', '畅玩任何一款游戏均可领取金币哦');
        } else if (task.name === 'weixin' || task.name === 'douyin') {
          if(task.name == 'weixin') {
            setWeixinText(task.tips.split('\n'));
          }
          if(task.name == 'douyin') {
            setDouyinText(task.tips.split('\n'));
          }
          setCdk(task.name);
        }
      }
    }
  }

  const [cdk, setCdk] = useState('');
  const [cdkIng, setCdkIng] = useState(false);
  const [cdkCode, setCdkCode] = useState(!prod ? '12345' : '');
  const cdkHandle = () => {
    if (!cdkCode) {
      Dialog.error('请输入兑换码');
      return;
    }
    if (cdkIng) {
      return;
    }
    setCdkIng(true);
    Dialog.loading();
    general.axios.post('/user/task/exchange', {type: cdk, code: cdkCode})
        .finally(() => {
          setCdkIng(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            Dialog.success('领取成功');
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;

        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  return <div>
    <div className="task-novice-new" style={{backgroundImage: 'url(/resources/task/novice-body-bg.png)'}}>
      {/* <div className="novice-head"><img src="/resources/task/new-novice-head.png" alt=""/></div> */}
      <div className="novice-body">
        {/* <img src="/resources/task/novice-top.png" alt=""/> */}
        <div className="container">
          {/* <div className="title">
            <img src="/resources/task/novice-left.png" alt=""/>
            任务内容
            <img src="/resources/task/novice-right.png" alt=""/>
          </div> */}
          <div className="tasks">
            {
              data?.map(item => <div className="task" onClick={() => handle(item)}>
                <div className="icon"><img src={item.icon} alt=""/></div>
                <div className="info">
                  <div className="name">
                    {item.title}
                    <div className="reward">
                      +{item.amount}金币
                    </div>
                  </div>
                  <div className="desc">
                    {item.remark}
                  </div>
                </div>
                <div className={'operate ' + (item.isCompleted ? (item.isReceivedReward ? 'completed' : 'complete') : '')}>
                  <div className="operatebg" style={{backgroundImage: 'url(/resources/task/'+(item.isCompleted ? (item.isReceivedReward ? 'completed-ground' : 'receive-ground') : 'go-ground')+'.png)'}}>
                    {item.isCompleted ? (item.isReceivedReward ? '已完成' : '领取') : item.btnTitle}
                  </div>
                </div>
              </div>)
            }


            {/* {tasks.map(task => data[task.id]? <div className="task" onClick={() => handle(task)}>
              <div className="icon"><img src={'/resources/task/new-novice-' + (task.id) + '.png'} alt=""/></div>
              <div className="info">
                <div className="name">
                  {task.name}
                  <div className="reward">+{data[task.id]?.balance}金币</div>
                </div>
                <div className="desc">{task.info}</div>
              </div>
              <div className={'operate' + (data[task.id]?.completed ? (data[task.id]?.taked ? ' completed' : ' complete') : '')}>
                <img src={'/resources/task/'+ (data[task.id]?.completed ? (data[task.id]?.taked ? 'been- completed' : 'go-receive') : (task.operalias)) +'.png'} />
              </div>
            </div>:null)} */}
          </div>
          {cdk ? <div className={'cdk ' + cdk}>
            <div className="mask" onClick={() => setCdk(null)}/>
            <div className="entity" style={{backgroundImage: 'url(/resources/task/novice-cdk.png)'}}>
              <div className="head"><img src={'/resources/task/' + cdk + '-title.png'} alt=""/></div>
              {cdk === 'weixin' ? <div className="info">
                任务流程<br/>
                <p>{weixinText[0]}</p>
                <p>{weixinText[1]}</p>
                <p>{weixinText[2]}</p>
                <p>{weixinText[3]}</p>
              </div> : null}
              {cdk === 'douyin' ? <div className="info">
                任务流程<br/>
                <p>{douyinText[0]}</p>
                <p>{douyinText[1]}</p>
                <p>{douyinText[2]}</p>
                <p>{douyinText[3]}</p>
              </div> : null}
              <div className="input">
                <input type="text" value={cdkCode} placeholder="请输入兑换码" autoComplete={false} onChange={e => setCdkCode(e.target.value)} onKeyUp={e => e.keyCode === 13 && cdkHandle()}/>
                <div className="btn" onClick={() => cdkHandle()}>确认</div>
              </div>
              {cdk === 'weixin' ? <a href="weixin://" className="operate">前往微信</a> : null}
            </div>
            <div className="close" onClick={() => setCdk(null)}>X</div>
          </div> : null}
        </div>
      </div>
    </div>
    {
      isCheckAuth?
      <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
                {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
              {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div>
      : null
      }
  </div>;
};