export default props => {
  return <div className="trade-notice">
    <div className="notice-info">
      账号交易采用转移小号的形式；买入或者卖出游戏角色，不影响您的游戏主账号使用。
    </div>
    <div className="notice-process">
      <div className="title"><img src="/resources/trade/title-1.png" alt=""/>购买流程</div>
      <div className="process">
        <img src="/resources/trade/process.png" alt=""/>
        <ul className="clearfix">
          <li>选择商品</li>
          <li>购买商品</li>
          <li>下载游戏</li>
          <li>登录游戏</li>
        </ul>
      </div>
      <div className="title"><img src="/resources/trade/title-2.png" alt=""/>买家须知</div>
      <div className="body">
        <p>角色描述、截图已通过官方核验。因时间因素造成的变化(排行榜、称号、装备到期等)，不视为信息失实。</p>
        <p>交易后角色直接转入您购买所使用的账号，登录游戏即可查收角色。</p>
        <p>交易过程仅限小号转移不涉及账号交易或换绑操作，无需担心购买的号被找回。</p>
        <p>购买时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有累计充值。</p>
        <p>交易完成后，不支持退货。</p>
        <p>交易完成的小号超过72小时后才可重新上架交易。</p>
        <p>所购买的小号不支持转游/转区操作。</p>
        <p>买家须知:因买家购买过程中操作失误产生的一切损失，由买家自行承担，平台概不负责。</p>
      </div>
    </div>
    <div className="notice-process">
      <div className="title"><img src="/resources/trade/title-1.png" alt=""/>出售流程</div>
      <div className="process">
        <img src="/resources/trade/process.png" alt=""/>
        <ul className="clearfix">
          <li>上传商品</li>
          <li>审核冻结</li>
          <li>买家购买</li>
          <li>出售成功</li>
        </ul>
      </div>
      <div className="title"><img src="/resources/trade/title-2.png" alt=""/>卖家须知</div>
      <div className="body">
        <p>提交出售申请后，待售小号在游戏中所有区服的角色都将被冻结待售， 无法登录。</p>
        <p>审核不成功或您选择下架商品，可重新登录游戏获得小号。</p>
        <p>出售时显示累计金额为该小号在该游戏同一小号下多个区服的角色所有 累计充值。</p>
        <p>小号出售成功后，该小号在本游戏中所有区服的角色会一同售出，但不 影响该小号在其他游戏中的角色。</p>
        <p>出售成功将收取5%的手续费（最低5元），剩余收益以平台币形式转至 您的账号下。平台币不可提现，仅能用于游戏充值。</p>
        <p>交易完成后，不支持找回。</p>
        <p>提交的小号在审核通过之后，若7天之内未完成交易，则自动下架 退回。</p>
        <p>当游戏发布停服公告或游戏问题临时下架后，该游戏的小号不论是否上 架都将无法交易，且该游戏已经上架的小号将自动下架。</p>
        <p>卖家须保证所有提供信息属实，因虚假信息导致的纠纷，由卖家承担 责任。</p>
        <p>卖家须知：因卖家出售过程中操作失误产生的一切损失，由卖家自行 承担，平台概不负责。</p>
      </div>
    </div>
  </div>;
};