import React, {useEffect, useState} from "react";
import KeepScroll from "../../../lib/KeepScroll";
import OneDay from "./OneDay";
import TwoDay from "./TwoDay";
import ThreeDay from "./ThreeDay";
import general from "../../../general";
import $ from "jquery";
import Dialog from "../../../lib/Dialog";
import useActiveState from "../../../lib/useActiveState";
import {useHistory, useLocation} from "react-router-dom";

const types = [
  {
    id: 'one-day',
    text: '第一天',
    component: OneDay,
  },
  {
    id: 'two-day',
    text: '第二天',
    component: TwoDay,
  },
  {
    id: 'three-day',
    text: '第三天',
    component: ThreeDay,
  }
];

export default props => {
  const [typeIndex, setTypeIndex] = useState(0);
  const [datas, setDatas] = useState([]);
  const [user] = useActiveState('user');
  const [ruleState, setRuleState] = useState(false);
  const [loadState, setLoadState] = useState(false)

  const load = () => {
    if(!general.isLogin()) {
      setLoadState(true);
      return false;
    }
    Dialog.loading();
    general.axios.post('/user/active2203/getIndexData')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setDatas(data);
        let days = data.day;
        if(days > 3) {
          days = 3;
        }
        if(days > 1) {
          setTypeIndex((days-1));
        }
        setLoadState(true)
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    load();
  }, [props.active]);

  // console.log(data);

  // const reload = () => {
  //   load();
  // }
  
  return <div className="couple-index">
      <div className="top">
        <div className="rule" onClick={()=>setRuleState(true)}>
          活动规则
        </div>
        <img src="/resources/party/new-couple-top.png" />
      </div>
      <div className="couple-head">
        {types.map((item, index) => <div className={'item' + (index === typeIndex ? ' active' : '')} onClick={() => setTypeIndex(index)}>
            {item.text}
            <div className="icon">
              <img src={"/resources/party/"+item.id+".png"} />
            </div>
          </div>)}
      </div>
      <div className="couple-body">
          {types.map((item, index) => <div className={'type-item' + (index === typeIndex ? ' active' : '')}>
            <KeepScroll active={props.active && index === typeIndex ? 'active' : ''}>
              <item.component active={props.active && index === typeIndex ? 'active' : ''} action={props.action} datas={datas} />
            </KeepScroll>
          </div>)}
      </div>

      {
        typeIndex == 0 ? 
        <div className="tips">
            *金币可用于游戏充值，比例为10（金币）：1（元）
        </div>
        : null
      }

      {/** 活动规则 **/}
      {ruleState ? 
      <div className="rule-bounced">
        <div className="rule-index">
          <div className="rule-content">
            <p>1.本活动仅限新用户参加，<span style={{fontWeight:"bold"}}>新用户仅注册7日</span>内可进入活动页面领取相应福利；</p>
            <p>2.活动福利<span style={{fontWeight:"bold"}}>需要分三天领取</span>，首次打开活动页面可领取第一天的新人福利，第二次（不能是同一天，但可以间隔）打开可领取第二天的新人福利，以此类推；</p> 
            <p>3.<span style={{fontWeight:"bold"}}>活动结束后未领取的金币、648充值卡和代金券等福利失效</span>，不可补领；</p>
            <p>4.请勿使用非正常手段刷取新人福利，一经发现将取消违规所得并封禁相应账号；</p>
            <p>活动解释权归麦游网络所有</p>
          </div>
          <div className="close" style={{backgroundImage:"url(/resources/party/close.png)"}} onClick={()=>{setRuleState(false)}}></div>
        </div>
      </div>
      : null}
  </div>
};