import {useState} from "react";
import Today from "./new/Today";
import Week from "./new/Week";
import Reserve from "./new/Reserve";
import KeepScroll from "../../lib/KeepScroll";
import useActiveState from "../../lib/useActiveState";

const router = [
  {
    id: 'today',
    component: Today,
    title: '今日首发',
  },
  {
    id: 'week',
    component: Week,
    title: '一周新游Top10',
  },
  {
    id: 'reserve',
    component: Reserve,
    title: '新游预约',
  },
];

export default props => {
  const {aplus_queue} = window;
  const [routerIndex, setRouterIndex] = useState(props.type || props.type === 0 ? props.type : 0);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');

  //新游标签 埋点
  const clickTabOfTheNewGame = (index) => {
    setRouterIndex(index);
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTabOfTheNewGame', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname:router[index].title, agent: agent}]
    });
  }

  return <div className="index-new">
    {/* <div className="new-head width">
      {router.map((item, index) => <div className={'item ' + (index == routerIndex ? 'active' : '')} onClick={() => clickTabOfTheNewGame(index)}>
        <div className="icon">
          <img src={'/resources/index/new-' + item.id + '.png'} />
        </div>
        <div className="text">{item.title}</div>
      </div>)}
    </div> */}
    <div className="new-body">
      {router.map((item, index) => <div className={'new-body-item ' + (index == routerIndex ? 'active' : '')}>
        <KeepScroll active={props.active && index == routerIndex}>
          <item.component active={props.active && index == routerIndex}/>
        </KeepScroll>
      </div>)}
    </div>
  </div>;
};