import {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import {NavLink} from "../../lib/Navigation";
import Dialog from "../../lib/Dialog";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import { useLockFn } from "ahooks";

export default props => {
  const [calendar, setCalendar] = useState([]);
  const history = useHistory();
  const [app] = useActiveState('app');
  useEffect(() => {
    let days = [];
    let startDay = moment(moment().format('YYYY-MM-01')).day();
    if (startDay !== 0) {
      for (let i = 0; i < startDay; i++) {
        days.push(moment(moment().format('YYYY-MM-01')).add(-(i + 1), 'days'));
      }
      days.reverse();
    }
    let i = 0;
    while (true) {
      let day = moment(moment().format('YYYY-MM-01')).add(i, 'days');
      if (day.format('YYYY-MM-DD') === moment().add(1, 'months').format('YYYY-MM-01')) {
        break;
      }
      days.push(day);
      i++;
    }
    while (true) {
      if (days.length % 7 !== 0) {
        let tmp = days[days.length - 1];
        days.push(moment(tmp).add(1, 'days'));
      } else {
        break;
      }
    }
    setCalendar(days);
  }, []);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [signInfo, setSignInfo] = useState({});
  const [sign, setSign] = useState(false);
  const [dataSignInfo, setDataSignInfo] = useState({});
  const [user] = useActiveState('user');

  const load =  useLockFn(async () => {
    Dialog.loading();
    await general.axios.post('/getSignData')
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          // setLoaded(true);
          let {status, data} = res.data;
          console.log(data);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setData({...data, ...data.user_info});
            setSignInfo(data.sign_info);
            setDataSignInfo(data.user_info);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });
  // const load = () => {
  //   if (loading) {
  //     return;
  //   }
  //   setLoading(true);
  //   Dialog.loading();
  //   general.axios.post('/user/task/getData')
  //     .finally(() => {
  //       setLoading(false);
  //       Dialog.close();
  //     })
  //     .then(response => {
  //       setLoaded(true);
  //       let result = response.data;
  //       if (!result.status.succeed) {
  //         Dialog.error(result.status.error_desc);
  //       } else {
  //         setData(result.data);
  //         setSignInfo(result.data.sign_info);
  //         setDataSignInfo(result.data.user_info);
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // }

  // useEffect(() => {
  //   if (props.active && (!loaded || props.action === 'POP')) {
  //     load();
  //   }
  //   load();
  // }, [props]);

  useEffect(() => {
    load();
  }, []);

  const signOperate = useLockFn(() => {
    if (!user.mobile) {
      // e.preventDefault();
      // e.stopPropagation();
      Dialog.confirm({
        title: '没有绑定手机号，现在去绑定？',
        yes: () => {
          history.push('/user/phone');
        }
      });
    } else {
      // Dialog.info("test");
      if (!data.today_issign) {
        Dialog.loading();
        general.axios.post('/user/task/sign')
          .finally(() => {
            Dialog.close();
          })
          .then(response => {
            let result = response.data;
            if (!result.status.succeed) {
              Dialog.error(result.status.error_desc);
            } else {
              load();
            }
          })
          .catch(error => {
            Dialog.error(error.message);
          });
      } else {
        Dialog.info("今日已经签过到了");
      }
    }
  })
  console.log(data.vip_add_balance);

  return <div className="sign-index" style={{backgroundImage: 'url(/resources/task/bg.png)'}}>
    {/* <div className="task-title">任务中心</div> */}
    <div className="task-money">
      <div className="money">
        <div className="label">当前金币（个）</div>
        <div className="value">{data.balance ? parseFloat(data.balance) : null}</div>
      </div>
      <Link to="/user/bill?type=2" className="btn"><img src="/resources/task/money.png" alt=""/>金币记录</Link>
    </div>
    <div onClick={() => signOperate()} className="task-sign">
      <div className="title"><img src="/resources/task/sign.png" alt=""/></div>
      <div className="info">本月已连续签到：<span>{data.month_keep_day}天</span></div>
      <div className="progress">
        <div className="tips" style={{backgroundImage:'url(/resources/task/sign-tips.png)'}}>连续签满28天最高可获得768金币</div>
        <div><span style={{width: '' + (data.month_keep_day / 28 * 100) + '%'}}/></div>
        <ul>
          <li className={data.month_keep_day >= 3 ? 'active' : ''} style={{left: '12.741%'}}>
            <div className="value">+10</div>
            <div className="label">3天</div>
          </li>
          <li className={data.month_keep_day >= 7 ? 'active' : ''} style={{left: '30%'}}>
            <div className="value">+20</div>
            <div className="label">7天</div>
          </li>
          <li className={data.month_keep_day >= 15 ? 'active' : ''} style={{left: '57%'}}>
            <div className="value">+50</div>
            <div className="label">15天</div>
          </li>
          <li className={data.month_keep_day >= 28 ? 'active' : ''} style={{left: '100%'}}>
            <div className="value"><img src="/resources/task/sign-gift.png" alt=""/></div>
            <div className="label">28天</div>
          </li>
        </ul>
      </div>
      <div className={'btn ' + (data?.today_issign ? 'disable' : '')}>{data?.today_issign ? '已签到' : '签到+' + (data.today_sign_get_balance ?? '?')}</div>
      {data ?
      <div style={{textAlign:'center',color:'#666',paddingTop:'10px'}}>
        {(()=>{
            switch (user.vip_level) {
              case "1":
                return "子爵会员金币加成" + (data?.vip_add_balance);
                break;
              case "2":
                return "伯爵会员金币加成" + (data?.vip_add_balance);
                break;
              case "3":
                return "公爵会员金币加成" + (data?.vip_add_balance);
                break;
              case "4":
                return "国王会员金币加成" + (data?.vip_add_balance);
                break;
              case "5":
                return "皇帝会员金币加成" + (data?.vip_add_balance);
                break;
              default:
                break;
            }
        })()}
      {/* 皇帝会员金币加成20 */}
      </div>
      :null}
    </div>
    {/* <div className="sign-title">
      <img src="/resources/task/sign-title-milu.png" alt=""/>
      本月已经连续签到{data.month_keep_day}天
      本月最长连续签到{data.month_max_keep_day}天
    </div> */}
    <div className="sign-calendar">
      <div className="title">{moment().format('YYYY年MM月')}</div>
      <div className="main clearfix">
        <div className="item disable">日</div>
        <div className="item disable">一</div>
        <div className="item disable">二</div>
        <div className="item disable">三</div>
        <div className="item disable">四</div>
        <div className="item disable">五</div>
        <div className="item disable">六</div>
        {calendar.map(item => <div className={'item'
        + (item.format('YYYY-MM') !== moment().format('YYYY-MM') ? ' disable' : ' sign')
        + (data.sign_date_list?.find(item2 => item2 === item.format('YYYY-MM-DD')) ? ' active' : '')
        } style={{
          backgroundImage: 'url(' + (
            data.sign_date_list?.find(item2 => item2 === item.format('YYYY-MM-DD')) ? '/resources/task/sign2.png' : (item.format('YYYY-MM') === moment().format('YYYY-MM') ? '/resources/task/sign1.png' : '')
          ) + ')'
        }}>
          {item.format('DD')}
        </div>)}
      </div>
    </div>
    <div className="sign-operate">
      <NavLink to="/save?type=vip" className="vip">开通会员 享金币加成</NavLink>
      <div className="role" onClick={() => Dialog.alert('签到规则', <><div className="sign-record-desc">
        <p>1.普通用户每签到1次可获得1金币奖励，会员每签到一次可额外获得相应身份金币奖励（子爵额外得1金币，伯爵2金币，公爵3金币，国王5金币，皇帝20金币）；</p>
        <p>2.连续签到<span>3天／7天／15天／28天</span>，分别可领取 额外奖励<span>10金币／20金币／50金币／100金币</span>。 连续签到时间越长，金币越丰厚；</p>
        <p>3.普通用户连续签到满28天，可获得208个积 分；</p>
        <p>4.签到中断或每月第1天，连续签到天数将重新 进行计算；</p>
        <p>5.同一手机／设备／身份证视为同一用户；</p>
        <p>6.本活动最终解释权归{app.appname}官方所属 </p>
        </div>
      </>)}>
        查看签到规则
      </div>
    </div>
    {/* {loaded && !signInfo.today_issign && sign ? <div className="sign-alert"> */}
    {sign ? <div className="sign-alert">
      <div className="mask" onClick={() => setSign(false)}/>
      <div className="entity" style={{backgroundImage: 'url(/resources/task/sign-alert.png)'}}>
        <div className="info">恭喜您本次签到<br/>获得{data.today_sign_get_balance}金币</div>
        <div className="desc">您已经连续签到{data.month_keep_day}天</div>
        <div className="btn" onClick={() => setSign(false)}>好的</div>
      </div>
    </div> : null}
  </div>;
};