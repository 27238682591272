import {useCallback, useEffect, useRef, useState} from "react";
import {QuestionCircleOutlined, SyncOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {useLocalStorageState, useReactive, useSize} from "ahooks";
import general from "../../../general";
import {NavLink} from "../../../lib/Navigation";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import GameWelfare from "../GameWelfare";
import GameDesc from "../GameDesc";
import Manager from "../../components/Manager";

export default props => {
  const [game, setGame] = useState({});
  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  const [welfareMore, setWelfareMore] = useState(true);
  const [welfareMored, setWelfareMored] = useState(false);
  const welfareBodyRef = useRef();
  const welfareEntityRef = useRef();
  const welfareBodySize = useSize(welfareBodyRef);
  const welfareEntitySize = useSize(welfareEntityRef);

  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();

  useEffect(() => {
    if(welfareEntitySize.height && welfareBodySize.height) {
      !welfareMored && setWelfareMore(welfareEntitySize.height > welfareBodySize.height);
    }
  }, [welfareBodySize, welfareEntitySize]);

  const [rebateMore, setRebateMore] = useState(true);
  const [rebateMored, setRebateMored] = useState(false);
  const rebateBodyRef = useRef();
  const rebateEntityRef = useRef();
  const rebateBodySize = useSize(rebateBodyRef);
  const rebateEntitySize = useSize(rebateEntityRef);
  useEffect(() => {
    if(rebateEntitySize.height && rebateBodySize.height) {
      !rebateMored && setRebateMore(rebateEntitySize.height > rebateBodySize.height);
    }
  }, [rebateBodySize, rebateEntitySize]);

  const [introMore, setIntroMore] = useState(true);
  const [introMored, setIntroMored] = useState(false);
  const introBodyRef = useRef();
  const introEntityRef = useRef();
  const introBodySize = useSize(introBodyRef);
  const introEntitySize = useSize(introEntityRef);
  useEffect(() => {
    if(introEntitySize.height && introBodySize.height) {
      !introMored && setIntroMore(introEntitySize.height > introBodySize.height);
    }
  }, [introBodySize, introEntitySize]);

  const [companyMored, setCompanyMored] = useState(false);

  const [player, setPlayer] = useState();
  const [muted, setMuted] = useLocalStorageState('game-video-muted', true);
  const video = useReactive({});
  const [videoPlayState, setVideoPlayState] = useState();
  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
  }
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
  }

  const imgRef = useCallback(node => {
    if (node) {
      node.addEventListener('touchstart', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchmove', e => {
        e.stopPropagation();
      }, {passive: false});
      node.addEventListener('touchend', e => {
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);

  const [data, setData] = useState([]);
  const [init, setInit] = useState(false);

  const recommend = () => {
    if(!game.game_id) {
      return;
    }
    // Dialog.loading();
    general.axios.post('/base/newindex/GameLike', {game_id: game.game_id})
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }

        setInit(true);
        if (data.list.length > 6){
          setData(data.list.splice(0,6));
        }else{
          setData(data.list);
        }
        // props.setGame(game => ({...game, recommend_info: data.list}));
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }

  useEffect(()=>{
    // recommend();
    if(game && !init) {
      recommend();
    }
  }, [game])

  // console.log(game.game_feature_list ? game.game_feature_list[0].content : null);
  const [teseState, setTeseState] = useState(false);
  useEffect(() => {
    if(game.game_feature_list) {
      // console.log(game.game_feature_list[0].content);
      if(game.game_feature_list[0].content.trim()) {
        setTeseState(true);
      }
    }
  }, [game.game_feature_list]);

  // console.log(teseState);

  return <div className="game-index">
    {/* <div className="game-explain">
      {game.game_feature_list && game.game_feature_list[0].content && teseState ?  <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">游戏特色</div>
            {parseInt(game.is_both) ? <>
              <div className="info">双端互通</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined/></div>
            </> : null}
          </div>
        </div>
        <div className={'body ' + (welfareMored ? 'more' : '')} ref={welfareBodyRef}>
          <div className="entity" ref={welfareEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[0].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
          {welfareMore
              ? <div className={'more ' + (welfareMored ? 'ed' : '')} onClick={() => setWelfareMored(!welfareMored)}/>
              : null}
        </div>
      </div> : null} */}
      {/*} {game.game_feature_list && game.game_feature_list[1].content ? <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">返利</div>
            {game.allow_rebate ? <>
              <div className="info">手动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后20小时内到账！')}><QuestionCircleOutlined/></div>
            </> : <>
              <div className="info">自动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}><QuestionCircleOutlined/></div>
            </>}
          </div>
          {game.allow_rebate ? <Link to={'/rebate?id=' + game.game_id} className="operate">申请返利></Link> : null}
        </div>
        <div className={'body ' + (rebateMored ? 'more' : '')} ref={rebateBodyRef}>
          <div className="entity" ref={rebateEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[1].content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
          {rebateMore
              ? <div className={'more ' + (rebateMored ? 'ed' : '')} onClick={() => setRebateMored(!rebateMored)}/>
              : null}
        </div>
      </div> : null} 
    </div>*/}
    {/* <Link to={'/game/vip?id=' + game.game_id} className="game-vip">
      <img src="/resources/game/vip.png"/>
      <p>《{game.game_name || '?'}》VIP表</p>
      <span><img src="/resources/welfare/left-back.png" className="left-back"/></span>
    </Link> */}


    {/* <div className="game-imgs-intro">

      <div className="head">
        <div className="subject">
          <div className="title">游戏介绍</div>
          {
            game.game_feature_list && game.game_feature_list[0].content.trim() && teseState ? '' :
            <>
            {parseInt(game.is_both) ?
            <>
            <div className="info">双端互通</div>
            <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined/></div>
            </>
            : ''  }
            </>
          }
        </div>
      </div>

      <div className={"game-intro " + (introMored ? 'more' : '')} ref={introBodyRef}>
        <div className="entity">
          {game.game_introduce}
        </div>
        {introMore
          ? <div className={'more ' + (introMored ? 'ed' : '')} onClick={() => setIntroMored(!introMored)}/>
          : null}
      </div>
    </div> */}

    
    {/* <div className={"game-companyinfo " + (companyMored ? " ed" : "")} >
      <div className="companyinfo-item">
        <div className="name">
          供应商信息：
        </div>
        <div className="text">
          {game?.companyInfo?.company}
        </div>
        {game?.appVersion || (game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl) ?
        <div className="operate" onClick={() => setCompanyMored(!companyMored)}>
          {companyMored ? '收起↑' : '更多↓'}
        </div>
        : null}
      </div>
      {game?.appVersion ?
      <div className={"companyinfo-item " + (companyMored ? " " : " hidden")}>
        <div className="name">
          软件版本：
        </div>
        <div className="text">
          {game?.appVersion}
        </div>
      </div>
      : null}
      {game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl ?
      <div className={"companyinfo-item "  + (companyMored ? " " : " hidden")}>
        <div className="name">
          隐私权限：
        </div>
        <div className="text privacy">
          <div className="child-index">
            <div className="">权限信息</div>
            {
              game?.companyInfo?.roleUrl.indexOf('https') == -1 ?
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.roleUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.roleUrl} iframe={{title:"权限信息"}} user={false} className="links">查看</NavLink>
            }
          </div>
          <div className="child-index">
            <div className="">隐私政策</div>
            {
              game?.companyInfo?.privacyUrl.indexOf('https') == -1 ? 
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.privacyUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.privacyUrl} iframe={{title:"隐私政策"}} user={false} className="links">查看</NavLink>
            }
          </div>
        </div>
      </div>
      : null}
    </div> */}


    {/* <div className="game-explain">
      {game.game_feature_list && game.game_feature_list[0].content && teseState ?  <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">上线福利</div>
            {parseInt(game.is_both) ? <>
              <div className="info" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}>双端互通</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined/></div>
            </> : null}
          </div>
        </div>
        <div className={'body ' + (welfareMored ? 'more' : '')} ref={welfareBodyRef}>
          <div className="entity" ref={welfareEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[0].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2') : null}}/>
          {welfareMore
              ? <div className={'more ' + (welfareMored ? 'ed' : '')} onClick={() => setWelfareMored(!welfareMored)}/>
              : null}
        </div>
      </div> : null}


      {game.game_feature_list && game.game_feature_list[1].content.trim() && teseState ? <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">返利</div>
            {game.allow_rebate ? <>
              <div className="info" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后24小时内到账！')}>手动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后24小时内到账！')}><QuestionCircleOutlined/></div>
            </> : <>
              <div className="info" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}>自动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}><QuestionCircleOutlined/></div>
            </>}
          </div>
          {game.allow_rebate ? <NavLink to={'/rebate?id=' + game.game_id} className="operate">申请返利></NavLink> : null}
        </div>
        <div className={'body ' + (rebateMored ? 'more' : '')} ref={rebateBodyRef}>
          <div className="entity" ref={rebateEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[1].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r\n|\r|\n)/g, '$1<br>$2') : null}}/>
          {rebateMore
              ? <div className={'more ' + (rebateMored ? 'ed' : '')} onClick={() => setRebateMored(!rebateMored)}/>
              : null}
        </div>
      </div> : null}
    </div> */}

    <div className="game-explain" onClick={() => {setWelfareMored(true);Manager.push({event_id:'D-2-15'})}}>
      {game.game_feature_list && game.game_feature_list[0].content && teseState ?  <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">游戏福利</div>
          </div>
          <div className="operate" style={{transform:'translateX(7px) rotate(270deg) scale(0.32)'}}>
            <img src={"/resources/game/report_down" + ".png"} />
          </div>
        </div>
        <div className={'body '} ref={welfareBodyRef}>
          {/* <div className="entity" ref={welfareEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[0].content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/> */}
          <div className="entity" ref={welfareEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[0].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2') : null}}/>
          {/* {welfareMore
              ? <div className={'more ' + (welfareMored ? 'ed' : '')} />
              : null} */}
          {/* <div className="operate">
            <img src={"/resources/game/report_" + (welfareMored ? 'up' : 'down') + ".png"} />
          </div> */}
        </div>
      </div> : null}


      {welfareMored && <GameWelfare {...props} setWelfareMored={()=>setWelfareMored(false)}></GameWelfare>}
      {introMored ? <GameDesc {...props} setIntroMored={()=>setIntroMored(false)}></GameDesc> :null}

      {/*} {game.game_feature_list && game.game_feature_list[1].content ? <div className="item">
        <div className="head">
          <div className="subject">
            <div className="title">返利</div>
            {game.allow_rebate ? <>
              <div className="info">手动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，需要通过app手动申请返利，提交审核后20小时内到账！')}><QuestionCircleOutlined/></div>
            </> : <>
              <div className="info">自动返利</div>
              <div className="tips" onClick={() => Dialog.alert('温馨提示', '充值达到返利标准后，无需手动申请，游戏一般为次日0点会自动到账！')}><QuestionCircleOutlined/></div>
            </>}
          </div>
          {game.allow_rebate ? <Link to={'/rebate?id=' + game.game_id} className="operate">申请返利></Link> : null}
        </div>
        <div className={'body ' + (rebateMored ? 'more' : '')} ref={rebateBodyRef}>
          <div className="entity" ref={rebateEntityRef} dangerouslySetInnerHTML={{__html: game.game_feature_list ? game.game_feature_list[1].content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2') : null}}/>
          {rebateMore
              ? <div className={'more ' + (rebateMored ? 'ed' : '')} onClick={() => setRebateMored(!rebateMored)}/>
              : null}
        </div>
      </div> : null} */}
    </div>

    <div className="game-imgs-intro" onClick={() => {setIntroMored(true);Manager.push({event_id:'D-2-16'})}}>
      <div className="head">
        <div className="subject">
          <div className="title">游戏介绍</div>
          {
            game.game_feature_list && game.game_feature_list[0].content.trim() && teseState ? '' :
            <>
            {parseInt(game.is_both) ?
            <>
            <div className="info">双端互通</div>
            <div className="tips" onClick={() => Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}><QuestionCircleOutlined/></div>
            </>
            : ''  }
            </>
          }
        </div>
        <div className="operate" style={{transform:'translateX(7px) rotate(270deg) scale(0.32)'}}>
            <img src={"/resources/game/report_down" + ".png"} />
          </div>
      </div>

      {/* <div className="game-intro">
        {game.game_introduce}
      </div> */}

      <div className={"game-intro "} ref={introBodyRef}>
        <div className="entity">
          {game.game_introduce}
        </div>
      </div>
    </div>
    
    {/* <Link to={'/game/vip?id=' + game.game_id} className="game-vip">
      <img src="/resources/game/vip.png"/>
      <p>《{game.game_name || '?'}》VIP表</p>
      <img className="left-back" src={"/resources/game/report_up.png"} />
    </Link> */}

    {/* <div className={"game-companyinfo " + (companyMored ? " ed" : "")} onClick={() => setCompanyMored(!companyMored)} >
      <div className="companyinfo-item">
        <div className="name">
          供应商信息：
        </div>
        <div className="text">
          {game?.companyInfo?.company}
        </div>
        {game?.appVersion || (game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl) ?
        <div className="operate">
          <img src={"/resources/game/report_" + (companyMored ? 'up' : 'down') + ".png"} />
        </div>
        : null}
      </div>
      {game?.appVersion ?
      <div className={"companyinfo-item " + (companyMored ? " " : " hidden")}>
        <div className="name">
          软件版本：
        </div>
        <div className="text">
          {game?.appVersion}
        </div>
      </div>
      : null}
      {game?.companyInfo?.filingCode ?
      <div className={"companyinfo-item " + (companyMored ? " " : " hidden")}>
        <div className="name">
          软件备案：
        </div>
        <div className="text">
          {game?.companyInfo?.filingCode}
        </div>
      </div>
      : null}
      {game?.companyInfo?.privacyUrl && game?.companyInfo?.roleUrl ?
      <div className={"companyinfo-item "  + (companyMored ? " " : " hidden")}>
        <div className="name">
          隐私权限：
        </div>
        <div className="text privacy">
          <div className="child-index">
            <div className="">权限信息</div>
            {
              game?.companyInfo?.roleUrl.indexOf('https') == -1 ?
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.roleUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.roleUrl} iframe={{title:"权限信息"}} user={false} className="links">查看</NavLink>
            }
          </div>
          <div className="child-index">
            <div className="">隐私政策</div>
            {
              game?.companyInfo?.privacyUrl.indexOf('https') == -1 ? 
              <div className="links" onClick={()=>{window.open(game?.companyInfo?.privacyUrl, "_blank")}}>
                查看
              </div>
              :
              <NavLink to={game?.companyInfo?.privacyUrl} iframe={{title:"隐私政策"}} user={false} className="links">查看</NavLink>
            }
          </div>
        </div>
      </div>
      : null}
    </div> */}

    <div className="game-games" style={{backgroundColor:'#fff',borderRadius:'13px',overflow:'hidden'}}>
      <div className="head">
        <div className="text">类似游戏</div>
        <div className="operate" onClick={() => {
          Manager.push({event_id:'D-2-17'})
          recommend()
        }}><SyncOutlined className="icon"/>换一批</div>
      </div>
      <div className="content">
        <div className="body">
          {data?.map((game, index) => <Link to={'/game?id=' + game.game_id} className={"item " + (index == 4 ? 'clear' : '')}>
            <div className="entity">
              <div className="icon">
                <Image src={game.game_image.thumb}/>
                {
                  game?.lablePosition == 2 && game.top_lable != '' ?
                  <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
                    {game.top_lable}
                  </div>
                  : null
                }
              </div>
              <div className="text">
                <div className="name">
                  {/* {game.top_lable != '' ? <div className="span">{game.top_lable }</div> : null } */}
                  <div className={" " + (game.nameRemark ? " name-new" : "")}>
                    {game?.lablePosition == 1 && game.top_lable != '' ? <div className="span">{game.top_lable}</div> : null }
                    {game.game_name}
                  </div>
                </div>
                <div className="info1">
                {game.game_classify_type.trim()}
                </div>
                <div className="info2">
                  {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : <span style={{color:infoTextColor}}>{game.introduction}</span>}
                </div>
                {
                  game.bottom_lable ? 
                  <>
                  {Object.keys(game.bottom_lable).length > 0 ? 
                  <div className="info3">
                    <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                  </div> : null }
                  </>
                  : null
                }

                {/* {
                Object.keys(game.bottom_lable) && Object.keys(game.bottom_lable).length > 0 ?
                <div className="info3">
                    <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
                </div>
                :null
                } */}
                {/* {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null} */}
              </div>
            </div>

            {/* {(() => {
              if(item.theme_style == 'all_image') {
                if(game?.banner_url) {
                  return <div className="entity-img">
                    <img src={game?.banner_url} />
                  </div>
                }
              } else if (item.theme_style == 'first_image') {
                if(index == 0) {
                  return <div className="entity-img">
                    <img src={game?.banner_url} />
                  </div>
                }
              }
            })()} */}
          </Link>)}
        </div>
      </div>
    </div>
    <NavLink onClick={()=>Manager.push({event_id:'D-2-18'})} to={'/feedback?type=game&id=' + game.game_id} className="game-help">
      游戏有问题？<span>点击反馈</span>
    </NavLink>
  </div>;
};