import React, {useEffect, useState} from "react";
import Type from "./Type";
import Hot from "./Hot";
import New from "./New";
import {DownOutlined, SearchOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";
import $ from "jquery";
import {redux} from "../../general";
import {CSSTransition} from "react-transition-group";
import Active from "../../lib/active/Active";
import useActiveState from '../../lib/useActiveState';
import {useInterval} from "ahooks";

const types = [
  {id: 1, title: 'BT游戏', name: 'bt'},
  {id: 2, title: '折扣游戏', name: 'discount'},
  {id: 3, title: 'H5游戏', name: 'h5'},
];
const router = [
  {
    id: 'type',
    component: Type,
    title: '分类',
  },
  {
    id: 'hot',
    component: Hot,
    title: '热门榜',
  },
  {
    id: 'new',
    component: New,
    title: '新游榜',
  },
];

export default props => {
  const {aplus_queue} = window;
  const [user] = useActiveState('user');
  const [config] = useActiveState('config');
  const [typeIndex, setTypeIndex] = useState(0);
  const [typeing, setTypeing] = useState(false);
  const typeTo = typeIndex => {
    setTypeIndex(typeIndex);
    setTypeing(false);
  }
  const [routerIndex, setRouterIndex] = useState(0);

  useEffect(() => {
    $('html,body').scrollTop(0);
  }, [typeIndex]);

  useEffect(() => {
    redux.subscribe(() => {
      let type = redux.getState().screenRouter?.type;
      if (type || type === 0) {
        setRouterIndex(type);
      }
    });
  }, []);


  const [searchRollList, setSearchRollList] = useState([]); //滚动搜索

  useEffect(()=>{
    if(config) {
      setSearchRollList(config.app_home_search_default_title_list)
    }
  }, [config, config.app_home_search_default_title_list]);

  const begin = () => {
    if(searchRollList && searchRollList.length > 0) {
      let info = searchRollList;
      info.push(searchRollList[0]);
      info.splice(0,1);
      setSearchRollList('');
      setSearchRollList(info);
    }
  }
  
  useInterval(()=>{
    begin();
  }, 3000);

  useEffect(() => {
    if(props.active) {
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['FindGameViewAppear', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username')}]
      });
    }
  }, [props.active]);

// console.log(props);
  return <div className="game-index">
    <div className="game-title width">
      <div className="container">


        <div className='new-search' >
          <Link className='input' to={'/search'}>
            <img src="/resources/user/ss.png" className="icon" />
            {/* <input type="text" placeholder={config.app_home_search_default_title_list ? config.app_home_search_default_title_list[0] : '' || '搜索'}/> */}
            {/* <input type="text" placeholder={searchRollList !== undefined ? searchRollList.length > 0 ? searchRollList[0]: '搜索' : '搜索'}/> */}
            <div className='input_text'>
              {
                searchRollList !== undefined ? searchRollList.length > 0 ? searchRollList[0]: '搜索' : '搜索'
              }
            </div>
          </Link>
        </div>
        {/* <div className="title" onClick={() => setTypeing(true)}>
          {types[typeIndex].title}
          <DownOutlined/>
        </div> */}
        <Link to={'/service'} className="service-game"><img src="/resources/index/index-service-1.png" /></Link>
        {/* <Link to={'/search'}><SearchOutlined className="search"/></Link> */}
      </div>
    </div>
    <CSSTransition in={typeing} timeout={250} unmountOnExit={true}>
      <div className="game-types">
        <div className="mask" onClick={() => setTypeing(false)}/>
        <div className="items width">
          {types.map((item, index) => <div className={'item ' + (index == typeIndex ? 'active' : '')} onClick={() => typeTo(index)}>
            <div className="icon" style={{backgroundImage: 'url(/resources/game/' + item.name + '.png)'}}></div>
            <div className="text">{item.title}</div>
          </div>)}
        </div>
      </div>
    </CSSTransition>
    {/* <div className="game-menu width">
      {router.map((item, index) => <div className={'game-menu-item ' + (index == routerIndex ? 'active' : '')} onClick={() => setRouterIndex(index)}>{item.title}</div>)}
    </div> */}
    <div className="game-main">
      {router.map((item, index) => <div className={'game-main-item' + (index == routerIndex ? ' active' : '')}>
        <div className={'game-main-item' + (index == routerIndex ? ' active' : '')}>
          <Active active={props.active && index == routerIndex}>
            <item.component active={props.active && index == routerIndex} type={typeIndex + 1}/>
          </Active>
        </div>
      </div>)}
    </div>
  </div>;
};