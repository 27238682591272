import {useEffect, useState} from "react";
import general from "../../general";
import moment from "moment";
import useActiveState from "../../lib/useActiveState";
import {useLockFn} from "ahooks";
import {useHistory} from "react-router-dom";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [user] = useActiveState('user');
  const [data, setData] = useState(general.rebate || {});
  useEffect(() => {
    console.log(data);
  }, [data]);
  const [confirm, setConfirm] = useState();
  const submit = useLockFn(async () => {
    if (!confirm) {
      setConfirm(true);
      return;
    }
    Dialog.loading();
    await general.axios.post('/user/rebate/submit', {
      ...data,
      recharge_time: data.last_recharge_time,
      recharge_amount: data.can_rebate_amount,
      game_account: user.username,
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setConfirm(false);
            console.log(data);
            history.replace('/rebate/success?id=' + data.rebeta_id);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  });

  return <div className="rebate-submit">
    <div className="input-group">
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/game.png" alt=""/></div> */}
        <div className="name">申请游戏</div>
        <div className="value">{data.game_name}</div>
      </div>
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/user.png" alt=""/></div> */}
        <div className="name">申请账号</div>
        <div className="value">{user.member_name}</div>
      </div>
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/time.png" alt=""/></div> */}
        <div className="name">充值时间</div>
        <div className="value">{moment(data.last_recharge_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
      </div>
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/amount.png" alt=""/></div> */}
        <div className="name">充值金额</div>
        <div className="value"><span>{data.can_rebate_amount}元</span></div>
      </div>
    </div>
    {/* <div className="input-group">
      <div className="input-item">
        <div className="name">充值时间</div>
        <div className="value">{moment(data.last_recharge_time * 1000).format('YYYY-MM-DD HH:mm:ss')}</div>
      </div>
      <div className="input-item">
        <div className="name">充值金额</div>
        <div className="value"><span>{data.can_rebate_amount}元</span></div>
      </div>
    </div> */}
    <div className="input-group">
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/server.png" alt=""/></div> */}
        <div className="name">游戏区服</div>
        <div className="value">{data.server_name}</div>
      </div>
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/role.png" alt=""/></div> */}
        <div className="name">角色名</div>
        <div className="value">{data.role_name}</div>
      </div>
      <div className="input-item">
        {/* <div className="icon"><img src="/resources/rebate/id.png" alt=""/></div> */}
        <div className="name">角色ID</div>
        <div className="value">{data.role_id}</div>
      </div>
    </div>
    <div className="rebate-content">
      <textarea rows="4" value={data.remark} onChange={e => setData({...data, remark: e.target.value})} placeholder="请您仔细核对线下活动是否有自选道具需要选择，请您选择 后在备注栏填写；返利问题，游戏问题，大额充值特殊需求 可直接联系专属客服。"/>
    </div>
    <div className="rebate-operate" onClick={submit}>提交申请</div>
    {confirm ? <div className="rebate-confirm">
      <div className="container">
        <div className="body">
          <div className="close" onClick={() => setConfirm(false)}><img src="/resources/user/close.png" /></div>
          {/* <div className="icon"><img src="/resources/rebate/confirm.png" alt=""/></div> */}
          <div className="title">特别提醒</div>
          <div className="info">
            <p>1.返利以单日累计充值总和计算，如您今日还 需要充值强烈建议您完成后续充值后再提 交，可以拿到更高比例的返利哦！</p>
            <p>2.部分游戏活动期间返利为活动时间内累计充 值总和，请您在活动结束后统一提交，活动 详情请查看游戏活动或咨询客服；</p>
          </div>
        </div>
        <div className="btns">
          <div className="btn" onClick={() => setConfirm(false)}>我再想想</div>
          <div className="btn" onClick={submit}>继续提交</div>
        </div>
      </div>
    </div> : null}
  </div>;
};