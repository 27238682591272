import React, {useEffect, useState} from "react";
import general from "../../general";
import $ from "jquery";
import {QuestionCircleOutlined,} from '@ant-design/icons';
import moment from "moment";
import {useUpdateEffect, useScroll} from "ahooks";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {CSSTransition} from "react-transition-group";
import {Link} from "react-router-dom";
import {InfoCircleOutlined} from '@ant-design/icons';
import useActiveState from "../../lib/useActiveState";

export default props => {
  const [explainState, setExplainState] = useState(false);
  const explain = () => {
    Dialog.alert('问答须知', <div className="qa-explain">
      想对新接触的游戏有更多的了解？游戏问答让萌新有地方问，让大神为您解答<br/>
      问答利益<br/>
      理性提问，中肯发言<br/>
      问的清楚，答的明白<br/>
    </div>);
  }
  useEffect(() => {
    props.setOperate(<QuestionCircleOutlined onClick={()=>setExplainState(true)}/>);
  }, []);
  const [data] = useState(general.question ?? {});
  const [loading, setLoading] = useState();
  const [page, setPage] = useState(1);
  const [datas, setDatas] = useState([]);
  const [more, setMore] = useState(true);
  const [questions] = useActiveState('questions');
  const [init, setInit] = useState(false);
  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Dialog.loading();
    let tmp = {
      question_id: props.query.id,
      pagination: {page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/qa/getAnswers', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data: {list, paginated}} = res.data;
        setInit(true);
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          if (parms.page) {
            setPage(parms.page);
          }
          setDatas(datas => parms.page === 1 || !parms.page ? list : [...datas, ...list]);
          setMore(paginated.more && list.length > 0);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  useEffect(() => {
    load();
  }, []);

  const scroll = useScroll();
  useUpdateEffect(() => {
    if (props.active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, props.active]);

  const [answer, setAnswer] = useState(false);
  const [content, setContent] = useState('');
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (!content) {
      return;
    }
    if (submiting) {
      return;
    }
    setSubmiting(true);
    Dialog.loading();
    general.axios.post('/base/qa/submitAnswer', {
      question_id: props.query.id,
      content: content,
    })
      .finally(() => {
        setSubmiting(false);
        Dialog.close();
      })
      .then(response => {
        let {status} = response.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          Dialog.success('提交成功', () => {
            setAnswer(false);
            setContent('');
          });
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }

  // console.log(questions);
  // console.log(general.question);
  return <div className="qa-detail">
    <div className="qa-q">
      <Link to={'/user/record?id=' + questions.user_id} className="avatar"><Image src={questions.user_icon} alt=""/></Link>
      <div className="main">
        <div className="info">
          <div className="name">
            <div className="nick-name">{questions.user_nickname}</div>
            {/* <div className="level-img"><img src={"/resources/user/" + questions.user_level + ".png"} /></div> */}
          </div>
          {/* <div className="time">{moment(data.time * 1000).format('MM-DD HH:mm')}</div> */}
          {/* <div className="time">{data.time?<>{moment(data.time * 1000).format('MM-DD HH:mm')}</> : ''}</div> */}
        </div>
        <div className="desc">
          <div className="icon"><img src="/resources/user/quest02.png"/></div>
          <div className="text">{questions.question}</div>
        </div>
      </div>
    </div>
    <div className="qa-info">
      <div className="icon"><img src="/resources/user/quest03.png" /></div>
      <div className="text">共有<span>{datas.length}</span>条玩家回答</div>
    </div>
    <div className="qa-as">
      {datas.map(item => <div className="qa-a">
        <Link to={'/user/record?id=' + item.user_id} className="avatar"><Image src={item.user_icon} alt=""/></Link>
        <div className={"main " + (item.is_official == '1'? 'authority' :'')}>
          <div className="info">
            {/* <div className="name">{item.user_nickname}</div> */}
            <div className="name">
              {item.is_official == '1' ? <div className="official-tit">
                官
              </div> : ''}
              <div className="nick-name">{item.user_nickname}</div>
              {/* <div className="level-img">{item.is_official != '1' ? <img src={"/resources/user/" + item.user_level + ".png"} />: null}</div> */}
            </div>
            {/* <div className="time">{moment(item.time * 1000).format('MM-DD HH:mm')}</div> */}
          </div>
          <div className="desc">{item.content}</div>
        </div>

        {item.reward_intergral_amount ? 
          <div className="qa-reward" style={{backgroundImage: 'url(/resources/comment/reward.png)'}}>
            <div className="text">{item.reward_intergral_amount * 1}</div>
          </div> 
          : null
        }
      </div>)}
      {datas.length === 0 && init ? <div className="recovery-empty"><img src="/resources/game/question_empty.png" alt=""/><br/>等待大神回复中...</div> : null}
    </div>
    <div className="qa-operate">
      {questions.played ? <div className="btn" onClick={() => setAnswer(true)}>我来回答</div> :
        props.query?.source == 'footprint' ? '' : <div className="disable"><InfoCircleOutlined className="icon" />只有近期玩过该游戏才能回答！</div>}
    </div>
    <CSSTransition in={answer} timeout={250} unmountOnExit={true}>
      <div className="qa-form">
        <div className="mask" onClick={() => setAnswer(false)}/>
        <div className="form">
          <div className="head">
            <div className="title">乐于助人的人，运气都不会差哦~</div>
            <div className={'btn ' + (!content ? ' disable' : '')} onClick={submit}>提交</div>
          </div>
          <div className="input">
            <textarea rows="5" placeholder="这个游戏我玩过，我来说说" value={content} onChange={e => setContent(e.target.value)}/>
          </div>
        </div>
      </div>
    </CSSTransition>
    {datas.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {explainState ? 
    <div className="qas-tips">
      <div className="tips">
        <div className="head">
          <div className="title">问答须知</div>
        </div>
        <div className="content">
          <div className="title">
            想对新接触的游戏有更多的了解?游戏问答---让萌新有地方问，让大神来为你解答。
          </div>
          <div className="etiquette">
            问答礼仪
          </div>
          <div className="matter">
            <img src="/resources/game/qa.png" alt=""/>
            理性提问，中肯发言:
          </div>
          <div className="matter">
            <img src="/resources/game/qa.png" alt=""/>
            问的清楚，答的明白
          </div>
        </div>
        <div className="operate" onClick={()=>setExplainState(false)}>
          知道了
        </div>
      </div>
    </div>
    : null}
  </div>;
};