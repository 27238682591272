import {useEffect, useState} from "react";
import general from "../../general";
import {useReactive} from "ahooks";
import Dialog from "../../lib/Dialog";
import ImagePicker from "../util/ImagePicker";

export default props => {
  const data = useReactive({
    topic_id: props.query.id
  });
  const [imgs, setImgs] = useState([]);
  const [submiting, setSubmiting] = useState(false);
  const submit = () => {
    if (submiting) {
      return;
    }
    if(data.content.length < 10) {
      Dialog.info('内容不能少于10个字');
      return;
    }
    
    setSubmiting(true);
    Dialog.loading();
    var formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    imgs.forEach((img, index) => {
      formData.append('pic_screenshots[' + index + ']', img.file);
    });
    general.axios.post('/base/comment/add', formData)
        .finally(() => {
          setSubmiting(false);
          Dialog.close();
        })
        .then(response => {
          let result = response.data;
          if (!result.status.succeed) {
            Dialog.error(result.status.error_desc);
          } else {
            Dialog.success('提交成功', () => general.history.goBack());
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    props.setOperate(<div className={'comment-submit-btn ' + (submiting ? 'disable' : '')} onClick={submit}>发布</div>);
  }, [submiting, imgs]);

  return <div className="comment-submit">
    <div className="content">
      <textarea name="content" rows="7" placeholder="请围绕该游戏画面、玩法、操作、氪金等方面进行点评。优质的评论将随机获得5-50个金币奖励，不少于10个字哦！" onChange={e => data.content = e.target.value}/>
    </div>
    <div className="imgs">
      <div>相应的游戏截图，最多9张哦！</div>
      <div>
        <ImagePicker multiple={true} max={9} files={imgs} setFiles={setImgs}/>
      </div>
    </div>
  </div>;
};