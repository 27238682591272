import {useEffect, useState, useCallback, React} from "react";
import {SearchOutlined} from "@ant-design/icons";
import general from "../../general";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import {useScroll, useThrottleEffect} from "ahooks";
import Dialog from "../../lib/Dialog";

export default props => {
  const history = useHistory();
  const [kw, setKw] = useState('');
  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const load = (parms = {}) => {
    Dialog.loading();
    let tmp = {
      game_name: kw,
      pagination: {page: page, count: 50},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/getAllGameList', tmp)
        .finally(() => {
          Dialog.close();
        })
        .then(response => {
          let result = response.data.data;
          if (parms.page) {
            setPage(parms.page);
          }
          setGames(games => parms.page === 1 || !parms.page ? result.game_list : [...games, ...result.game_list]);
          setMore(result.paginated.more);
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  useThrottleEffect(() => {
    //改为 kw不为空在调用
    if(kw) {
      load({page: 1});
    }
  }, [kw]);

  const scroll = useScroll();
  useEffect(() => {
    if (scroll.top < -80) {
      load({page: 1});
    }
    if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
      load({page: page + 1});
    }
  }, [scroll]);

  //搜索处理
  const [inputText, setInputText] = useState('');
  let isOnComposition = false;
  const handleComposition = (e) => {
    //中文输入状态
    if (e.type === 'compositionend') {
      isOnComposition = false;
      setInputText(e.target.value);
      setKw(e.target.value);
      return;
    } else if (e.type === 'compositionstart') {
      isOnComposition = true;
      return;
    } else if (e.type === 'compositionupdate') {
      isOnComposition = true;
      return;
    }
    isOnComposition = true;
  }

  const handleChange = (e) => {
    setInputText(e.target.value);
    if (!isOnComposition) {
      setKw(e.target.value)
    }
  }

  //当条件清空时，不调用问题处理
  const inputRef = useCallback(node => {
    if (node) {
      node.addEventListener('input', e => {
        if(e.inputType == 'deleteContentBackward') {
          setInputText(e.target.value);
          setKw(e.target.value);
          if(e.target.value == '') {
            load({page: 1});
          }
        }
      }, {passive: false});
    }
  }, []);

  return <div className="game-select">
    <div className="game-select-head">
      {/* <input type="text" value={kw} placeholder="请输入游戏名称" onChange={e => setKw(e.target.value)}/> */}
      <input type="text" value={inputText} placeholder="请输入游戏名称"
            onCompositionStart = {handleComposition}
            onCompositionUpdate = {handleComposition}
            onCompositionEnd = {handleComposition}
            onChange={handleChange} ref={inputRef}/>

      <div className="buttton" onClick={() => load({page: 1})}>
        <SearchOutlined className="icon"/>
        <div className="text">搜索</div>
      </div>
    </div>
    <div className="game-select-body">
      {games.map(game => <div className="item" onClick={() => {
        general.game = game;
        history.goBack();
      }}>
        <div className={"type type-" + game.gametype}>{game.gametype}</div>
        <div className="name">{game.gamename}</div>
      </div>)}
      <div className={'pull-to-load ' + (!more ? 'nomore' : '')}/>
    </div>
  </div>;
};