import Queue from "./Queue"

export default class Manager {

  static appkey = localStorage.getItem("appkey")
  static queue_array = JSON.parse(localStorage.getItem("event-queue")) || []

  static init = ()=>{
    if (!window.navigator.standalone){
      return
    }
    if (!this.appkey){
      Queue.init().then( appkey =>{
        localStorage.setItem("appkey",appkey)
        Queue.active({unid:appkey})
      })
    }else {
      Queue.active({unid:this.appkey})
    }
  }

  static push = (params)=>{
    if (!window.navigator.standalone){
      return
    }
    this.queue_array.push(params)
    this.update()
    
    if(this.queue_array.length >= 10){
      Queue.upload({events:this.queue_array,unid:this.appkey})
        .then(res=>{
          this.queue_array = []
          this.update()
        })
    }
  }

  static update = ()=>{
    localStorage.setItem('event-queue',JSON.stringify(this.queue_array))
  }
}