import {prod, url} from "../../env";
import {Link} from "react-router-dom";
import {PhoneNumberServer} from 'aliyun_numberauthsdk_web';
import React, {useEffect, useRef, useState} from "react";
import general from "../../general";
import {useInterval, useLocalStorageState} from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";

const phoneNumberServer = new PhoneNumberServer();

export default props => {
  const [app] = useActiveState('app');
  const {aplus_queue} = window;
  const [agreement, setAgreement] = useLocalStorageState('agreement', true);
  const [loging, setLoging] = useState();
  const phoneRef = useRef(null);
  const [phone, setPhone] = useLocalStorageState('phone', !prod ? '12345678901' : '');
  const [users, setUsers] = useState([]);
  const login = () => {
    if (!phone) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!/^\d{11}$/.test(phone)) {
      Dialog.error('手机号码格式不正确');
      return;
    }
    if (!agreement) {
      Dialog.error('请阅读下方的协议');
      return;
    }
    if (codeLoging) {
      codeLogin();
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.get('/getLoginToken')
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data: {tokenInfo: {accessToken, jwtToken}}} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          phoneNumberServer.checkAuthAvailable({
            phoneNumber: phone,
            accessToken: accessToken,
            jwtToken: jwtToken,
            success: (res) => {
              console.log(res);
              Dialog.close();
              Dialog.loading();
              phoneNumberServer.getVerifyToken({
                success: (res) => {
                  console.log(res);
                  Dialog.close();
                  Dialog.loading();
                  general.axios.post('/oneClickLogin', {phone_number: phone, sp_token: res.spToken})
                      .finally(() => {
                        setLoging(false);
                        Dialog.close();
                      })
                      .then(res => {
                        let {status, data} = res.data;
                        if (!status.succeed) {
                          setLoging(false);
                          Dialog.error(status.error_desc);
                          return;
                        }
                        let users = data.users;
                        if (users.length === 1) {
                          localStorage.setItem('token', users[0].token);
                          localStorage.setItem('user_id', users[0].id);
                          localStorage.setItem('username', users[0].username);
                          aplus_queue.push({
                            action: 'aplus.record',
                            arguments: ['login', 'login', {
                              type: 'oneClickLogin',
                              username: users[0].username,
                            }]
                          });
                          Dialog.success('登录成功', () => general.goBack(true));
                        } else {
                          setUsers(users);
                        }
                      })
                      .catch(err => {
                        console.error(err);
                        Dialog.error(err.message);
                      });
                },
                error: res => {
                  console.error(res);
                  setLoging(false);
                  // Dialog.error(res.msg);
                  Dialog.close();
                  setCodeLoging(true);
                }
              });
            },
            error: res => {
              console.error(res);
              setLoging(false);
              // Dialog.error(res.msg);
              Dialog.close();
              setCodeLoging(true);
            }
          });
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  const finalLogin = user => {
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/finalCellularLogin', {username: user.username, token: user.token})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_id', data.id);
          localStorage.setItem('username', data.username);
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['login', 'login', {
              type: 'oneClickLogin',
              username: data.username
            }]
          });
          Dialog.success('登录成功', () => general.goBack(true));
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }
  /*useEffect(() => {
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }, []);*/

  const [codeLoging, setCodeLoging] = useState(false);
  useEffect(() => {
    if (codeLoging) {
      codeGet();
      /*if (codeRef.current) {
        codeRef.current.focus();
      }*/
    }
  }, [codeLoging]);
  const codeRef = useRef();
  const [code, setCode] = useState('');
  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const codeGet = () => {
    if (codIng || codeTime) {
      return;
    }
    setCodIng(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: phone,
      code: 'code',
      type: '08',
    })
        .finally(() => {
          setCodIng(false);
          Dialog.close();
        })
        .then(res => {
          let {status} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            setCodeTime(60);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  };
  const codeLogin = () => {
    if (loging) {
      return;
    }
    if (!phone) {
      Dialog.error('请输入验证码');
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/verificaCodeLogin', {phone_number: phone, code})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          let users = data.users;
          if (users.length === 1) {
            localStorage.setItem('token', users[0].token);
            localStorage.setItem('user_id', users[0].id);
            localStorage.setItem('username', users[0].username);
            aplus_queue.push({
              action: 'aplus.record',
              arguments: ['login', 'login', {
                type: 'code',
                username: users[0].username
              }]
            });
            Dialog.success('登录成功', () => general.goBack(true));
          } else {
            setUsers(users);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  };

  return <div className="account-phonelogin">
    <div className="phonelogin-body">
      <div className="logo"><img src={app.applogo} alt=""/></div>
      <div className="body">
        <div className="input">
          <input name="phone" type="tel" value={phone} placeholder="输入本机手机号一键登录" onKeyUp={e => e.keyCode === 13 && login()} onChange={e => setPhone(e.target.value)} autofocus="autofocus" ref={phoneRef}/>
        </div>
        {codeLoging ? <div className="code">
          <input name="code" type="tel" value={code} placeholder="输入验证码" onKeyUp={e => e.keyCode === 13 && login()} onChange={e => setCode(e.target.value)} autofocus="autofocus" autoComplete="off" ref={codeRef}/>
          <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={codeGet}>
            {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
          </div>
        </div> : null}
      </div>
      <div className={'btn ' + (loging ? 'disable' : '')} onClick={login}>一键登录</div>
      <Link to="/login" className="btn-phone">更多登录></Link>
    </div>
    {users.length > 0 ? <div className="phonelogin-users">
      <div className="mask" onClick={() => setUsers([])}/>
      <div className="body">
        <div className="title">选择用户</div>
        <div className="users">
          {users.map(user => <div className="user" onClick={() => finalLogin(user)}>{user.username}</div>)}
        </div>
      </div>
    </div> : null}
    <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={() => setAgreement(true)}/>
        登录即代表您同意
        <Link to={'/iframe?url=' + url + '/userAgreement&title=用户协议'}>用户协议</Link>、
        <Link to={'/iframe?url=' + url + '/privacyPolicy&title=隐私政策'}>隐私政策</Link>
        <br/>
        接受免除或者限制责任、诉讼管辖约定等粗体标示条款
      </label>
    </div>
  </div>;
};